/* *****************************
 * Text
 **************************** */

.c-txt {
    &__2rem {
        font-size: 2rem;
        line-height: 40px;
        color: #707070;
        padding: 0 76px;
        margin-bottom: 90px;

        @include mq(lg) {
            font-size: 1.8rem;
            line-height: 36px;
            padding: 0 35px;
            margin-bottom: 60px;
        }

        @include mq(md) {
            font-size: 1.6rem;
            line-height: 26px;
            padding: 0 15px;
            margin-bottom: 35px;
            padding: 0;
        }

        &.mb0 {
            margin-bottom: 0;
        }

        &.tac {
            text-align: center;
        }

        &.non-padding {
            padding: 0;
        }

        span {
            color: #4fa9be;

            &.fsz16rem {
                font-size: 1.6rem;
                color: #b40404;
            }
        }
    }

    &__3rem {
        font-size: 3rem;
        @include yumincho-font;
        color: #393327;
        line-height: 44px;
        letter-spacing: 0.05em;
        padding: 0 140px;
        margin-bottom: 80px;

        @include mq(lg) {
            font-size: 2.4rem;
            line-height: 32px;
            margin-bottom: 60px;
            padding: 0 70px;
        }

        @include mq(md) {
            font-size: 1.8rem;
            line-height: 28px;
            margin-bottom: 40px;
            padding: 0 30px;
        }
    }

    &__red {
        font-size: 2.4rem;
        font-weight: 600;
        @include yumincho-font;
        line-height: 32px;
        color: #c51919;
        margin: 0 auto;

        @include mq(lg) {
            font-size: 1.8rem;
            line-height: 28px;
        }

        &.w74p {
            width: 74.3%;

            @include mq(md) {
                width: 100%;
            }
        }
    }

    &__box {
        background: #257d8f;
        color: #fff;
        @include yumincho-font;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 32px;
        border-radius: 5px;
        max-width: 79.61%;
        padding: 40px 30px;
        margin: 0 auto 50px;

        @include mq(lg) {
            max-width: 85%;
            font-size: 1.6rem;
            padding: 20px 15px;
            margin-bottom: 35px;
        }

        @include mq(md) {
            max-width: 100%;
        }

        &.mb85 {
            margin-bottom: 85px;

            @include mq(lg) {
                margin-bottom: 45px;
            }
        }
    }

    &__base {
        letter-spacing: 0.05em;
        margin-bottom: 50px;

        @include mq(md) {
            margin-bottom: 35px;
        }

        &.mb0 {
            margin-bottom: 0;
        }

        &.half-width {
            width: 54%;

            @include mq(lg) {
                width: 65%;
            }

            @media screen and (max-width: 576px) {
                width: 100%;
            }
        }
    }

    &__blue {
        @include yumincho-font;
        color: #4fa9be;
        padding: 12px;
        line-height: 21px;
        background: #fff;
        margin-top: -15px;
        margin-bottom: 20px;

        @include mq(lg) {
            margin-top: -10px;
            margin-bottom: 10px;
        }
    }
}
