.p-aesthe {
    &1 {
        &__inner {
            padding: 70px 0 75px;
            position: relative;

            &--decor {
                @extend .p-pay1__inner--decor;
            }

            &::before {
                content: '';
                z-index: 2;
                width: 100%;
                height: calc(12vw + 8px);
                background: url(../images/design1-3/wave-white.svg) no-repeat;
                background-size: cover;
                position: absolute;
                left: 0;

                bottom: calc(100% - 1px);
            }
        }
    }

    &2 {
        padding: 100px 0;
        background: #ebf7fa;

        @include mq(lg) {
            padding: 65px 0;
        }
    }

    &3 {
        padding: 120px 0;

        @include mq(lg) {
            padding: 65px 0;
        }
    }

    &4 {
        padding: 120px 0;
        background: #ebf7fa;

        @include mq(lg) {
            padding: 65px 0;
        }

        .l-container__1300px {
            section {
                > figure {
                    text-align: center;
                    padding: 0 15px;
                    margin-bottom: 60px;

                    @include mq(lg) {
                        padding: 0;
                        margin-bottom: 40px;
                    }

                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
