.p-info {
    &1 {
        background: #ebf7fa;
        &__inner {
            padding: 70px 0;
            position: relative;

            &--decor {
                @extend .p-pay1__inner--decor;
            }

            &--row {
                @include f;
                margin-bottom: 45px;

                &.flex-end {
                    justify-content: flex-end;

                    @include mq(md) {
                        justify-content: space-between;
                    }
                }

                .c-btn__skeleton--short {
                    margin-left: 16px;

                    @include mq(md) {
                        max-width: calc(50% - 7.5px);
                        margin-left: 0;
                    }
                }
            }

            &::before {
                content: '';
                z-index: 2;
                width: 100%;
                height: calc(12vw + 8px);
                background: url(../images/design1-1/wave2.svg) no-repeat;
                background-size: cover;
                position: absolute;
                left: 0;

                bottom: calc(100% - 1px);
            }
        }
    }
}
