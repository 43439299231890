/* *****************************
 * Other
 **************************** */

.c-block {
    &1 {
        margin-bottom: 120px;

        @include mq(lg) {
            margin-bottom: 60px;
        }

        &__inner {
            @include fsc;

            &--left {
                width: calc(50% - 22px);

                @include mq(md) {
                    width: 100%;
                    margin-bottom: 35px;
                }

                h2 {
                    font-size: 3rem;
                    letter-spacing: 0.05em;
                    line-height: 39px;
                    margin-bottom: 39px;
                    @include yumincho-font;
                    text-align: left;
                    color: #393327;
                    padding: 0;
                }

                h3 {
                    font-size: 2rem;
                    line-height: 26px;
                    color: #f90415;
                    text-align: center;
                    @include yumincho-font;
                }

                > figure {
                    img {
                        width: 100%;
                    }
                }
            }

            &--right {
                width: 50%;
                line-height: 0;

                @include mq(md) {
                    width: 100%;
                }

                iframe {
                    width: 100%;
                    @include mq(md) {
                        height: 50vw;
                    }
                }
            }

            &--note {
                @include fcc;
                margin-bottom: 60px;

                @include mq(lg) {
                    margin-bottom: 35px;
                }

                span,
                p {
                    font-size: 1.7rem;
                    line-height: 34px;

                    @include mq(lg) {
                        font-size: 1.6rem;
                        line-height: 26px;
                    }
                }

                span {
                    display: block;
                    margin-right: 30px;
                }

                p {
                    @include mq(lg) {
                        width: calc(100% - 78px);
                    }
                }
            }
        }
    }

    &2 {
        &__item {
            &:not(:last-child) {
                margin-bottom: 60px;

                @include mq(lg) {
                    margin-bottom: 40px;
                }
            }

            &--top {
                position: relative;
                margin-bottom: 50px;

                @include mq(lg) {
                    margin-bottom: 35px;
                }

                h4 {
                    line-height: 66px;
                    background: #f8eaa5;
                    border-radius: 5px;
                    padding: 0 10px;
                    @include yumincho-font;
                    color: #707070;
                    font-size: 3rem;
                    letter-spacing: 0.05em;
                    font-weight: 400;

                    @include mq(xl) {
                        font-size: 2.4rem;
                        line-height: 55px;
                    }

                    @include mq(lg) {
                        font-size: 1.8rem;
                        line-height: 26px;
                        padding: 10px;
                    }

                    @include mq(md) {
                        padding: 10px 100px 10px 10px;
                    }
                }

                > span {
                    position: absolute;
                    @include hobo-font;
                    font-size: 3.5rem;
                    line-height: 35px;
                    color: #fff;
                    z-index: 1;
                    bottom: 25px;
                    right: 50px;

                    @include mq(lg) {
                        font-size: 2.3rem;
                        right: 3%;
                        bottom: 30%;
                    }

                    @include mq(md) {
                        right: 25px;
                    }

                    &::before {
                        z-index: -1;
                        width: 92.3px;
                        height: 108.16px;
                        background: url(../images/common/svg/icon-point.svg) no-repeat;
                        background-size: cover;
                        content: '';
                        position: absolute;
                        top: 5%;
                        left: 25%;
                        transform: translate(-50%, -50%);

                        @include mq(lg) {
                            width: 68px;
                            height: 80px;
                            top: 10%;
                        }
                    }
                }
            }

            &--bot {
                @include fs;

                p {
                    width: 58%;
                    font-size: 2rem;
                    line-height: 40px;
                    color: #707070;

                    @include mq(xl) {
                        width: 55%;
                    }

                    @include mq(lg) {
                        font-size: 1.6rem;
                        line-height: 32px;
                    }

                    @include mq(md) {
                        width: 100%;
                        line-height: 28px;
                        margin-bottom: 25px;
                    }
                }

                figure {
                    width: 42%;
                    text-align: center;

                    @include mq(md) {
                        width: 100%;
                    }

                    img {
                        width: 100%;
                        max-width: 509px;

                        @include mq(md) {
                            max-width: 100%;
                        }
                    }

                    figcaption {
                        font-size: 1.4rem;
                        color: #707070;
                        line-height: 22px;
                        text-align: right;
                        margin-top: 15px;
                    }
                }
            }

            &--row {
                @include f;
            }

            &--box {
                width: 50%;
                padding: 25px;

                @include mq(lg) {
                    width: 100%;
                    padding: 25px 0;
                }

                figure {
                    text-align: center;
                    margin-bottom: 28px;

                    figcaption {
                        font-size: 2rem;
                        line-height: 26px;
                        margin-bottom: 25px;
                        font-weight: 600;
                        color: #707070;

                        @include mq(lg) {
                            font-size: 1.8rem;
                        }
                    }
                }

                p {
                    font-size: 2rem;
                    line-height: 26px;
                    font-weight: 400;
                    margin-bottom: 40px;

                    @include mq(lg) {
                        font-size: 1.8rem;
                    }
                }

                dl {
                    dt {
                        display: block;
                        width: 96.85px;
                        height: 96.85px;
                        background: #658185;
                        border-radius: 50%;
                        @include fcc;
                        color: #fff;
                        @include yumincho-font;
                        margin-right: 30px;

                        @include mq(lg) {
                            width: 80px;
                            height: 80px;
                            font-size: 1.4rem;
                            margin-right: 20px;
                        }

                        &.pink-bg {
                            background: #fb8da0;
                        }
                    }

                    dd {
                        width: calc(100% - 127px);
                        color: #878585;
                        @include yumincho-font;
                        font-size: 1.8rem;
                        line-height: 32px;

                        @include mq(lg) {
                            width: calc(100% - 100px);
                        }
                    }

                    .c-block2__item--row {
                        &:not(:last-child) {
                            margin-bottom: 45px;

                            @include mq(lg) {
                                margin-bottom: 25px;
                            }
                        }
                    }
                }
            }

            &--inner {
                background: #f6f6f6;
                padding: 30px 30px 40px;

                @include mq(lg) {
                    padding: 15px;
                }
            }
        }
    }

    &3 {
        @include fs;
        margin-bottom: 150px;

        @include mq(xl) {
            margin-bottom: 100px;
        }

        @include mq(lg) {
            margin-bottom: 65px;
        }

        @include mq(md) {
            margin-bottom: 50px;
        }

        &__content {
            width: calc(59.08% - 30px);

            @include mq(md) {
                width: 100%;
                margin-bottom: 35px;
            }

            .c-txt__2rem {
                margin-bottom: 64px;

                @include mq(lg) {
                    margin-bottom: 35px;
                }
            }

            &--row {
                @include fs;
                width: 100%;
                max-width: 650px;

                @include mq(md) {
                    max-width: 100%;
                }

                figure {
                    width: 100%;
                    max-width: calc(100% / 2 - 15px);

                    @include mq(md) {
                        max-width: calc(100% / 2 - 7.5px);
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }
        &__img {
            width: 40.92%;
            text-align: center;

            @include mq(md) {
                width: 100%;
            }

            img {
                max-width: 100%;
            }
        }
    }

    &4 {
        @include fs;
        margin-bottom: 70px;

        &.mb160 {
            margin-bottom: 160px;

            @include mq(lg) {
                margin-bottom: 80px;
            }

            @include mq(md) {
                margin-bottom: 45px;
            }
        }

        &__img {
            width: 41.07%;

            @include mq(md) {
                width: 100%;
            }

            img {
                @include mq(md) {
                    width: 100%;
                }
            }
        }

        &__content {
            width: calc(58.03% - 30px);

            @include mq(md) {
                width: 100%;
                margin-bottom: 35px;
            }
        }
    }
}

.c-procedure {
    margin-bottom: 70px;

    @include mq(lg) {
        margin-bottom: 35px;
    }

    ul {
        @include f;
        margin: 0 -10px;

        @media screen and (max-width: 576px) {
            margin: 0 -7.5px;
        }

        li {
            width: 100%;
            max-width: calc(100% / 6 - 20px);
            margin: 0 10px;
            position: relative;
            background: #4fa9be;
            padding: 25px 10px;
            border-radius: 5px;
            @include fcc;
            display: block;
            font-size: 2rem;
            letter-spacing: 0.05em;
            text-align: center;
            line-height: 26px;
            @include yumincho-font;
            color: #fff;

            @include mq(xl) {
                max-width: calc(100% / 4 - 20px);
                margin-bottom: 20px;
                height: 130px;
            }

            @include mq(lg) {
                max-width: calc(100% / 3 - 20px);
                margin-bottom: 20px;
                font-size: 1.8rem;
            }

            @media screen and (max-width: 576px) {
                max-width: calc(100% / 2 - 15px);
                margin: 0 7.5px 15px;
                height: 110px;
                padding: 10px;
            }

            &.is-active {
                padding: 15px;
                border: 8px solid #e9cd41;

                @include mq(lg) {
                    border-width: 4px;
                }
            }

            &:nth-child(3) {
                &::before {
                    @include mq(lg) {
                        display: none;
                    }

                    @media screen and (max-width: 576px) {
                        display: block;
                    }
                }
            }

            &:nth-child(4) {
                &::before {
                    @include mq(xl) {
                        display: none;
                    }

                    @include mq(lg) {
                        display: block;
                    }
                }
            }

            &:nth-child(2n + 2) {
                &::before {
                    @media screen and (max-width: 576px) {
                        display: none;
                    }
                }
            }

            &:not(:last-child) {
                &.is-active {
                    padding: 15px;
                    border: 8px solid #e9cd41;

                    @include mq(lg) {
                        border-width: 4px;
                    }

                    &::before {
                        left: calc(100% + 8px);

                        @include mq(lg) {
                            left: calc(100% + 4px);
                        }
                    }
                }

                &::before {
                    width: 20px;
                    height: 30px;
                    content: '';
                    position: absolute;
                    left: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    background: url(../images/common/svg/angle-right.svg) no-repeat;
                    background-size: cover;

                    @media screen and (max-width: 576px) {
                        width: 15px;
                        height: 22px;
                    }
                }
            }
        }
    }
}

.c-para {
    &1 {
        section {
            > figure {
                text-align: center;

                &:not(:last-child) {
                    margin-bottom: 40px;
                }

                &.mb70 {
                    margin-bottom: 70px;

                    @include mq(lg) {
                        margin-bottom: 35px;
                    }
                }

                img {
                    max-width: 100%;
                }
            }

            > p {
                margin-bottom: 50px;

                @include mq(lg) {
                    margin-bottom: 35px;
                }
            }
        }

        &__row {
            @include fs;
            margin-bottom: 200px;

            @include mq(xl) {
                margin-bottom: 100px;
            }

            @include mq(lg) {
                margin-bottom: 80px;
            }

            &.mb80 {
                margin-bottom: 80px;

                @include mq(lg) {
                    margin-bottom: 45px;
                }
            }

            dd {
                font-size: 2.4rem;
                line-height: 38px;
                color: #393327;
                letter-spacing: 0.05em;
                @include yumincho-font;
                font-weight: 600;

                @include mq(lg) {
                    font-size: 1.8rem;
                    line-height: 30px;
                }

                @include mq(md) {
                    font-size: 1.6rem;
                    line-height: 28px;
                }

                &:not(:last-child) {
                    margin-bottom: 40px;

                    @include mq(lg) {
                        margin-bottom: 25px;
                    }
                }
            }

            &--text {
                width: 50%;

                @include mq(md) {
                    width: 100%;
                    margin-bottom: 25px;
                }
            }

            &--img {
                width: calc(50% - 10px);

                @include mq(md) {
                    width: 100%;
                }

                &.minus20px {
                    width: calc(50% - 20px);

                    @include mq(md) {
                        width: 100%;
                    }
                }

                img {
                    width: 100%;
                }
            }
        }

        &__row2 {
            @include fs;
            margin-bottom: 40px;

            @include mq(md) {
                margin-bottom: 30px;
            }

            &.more-width-img {
                .c-para1__row2 {
                    &--text {
                        max-width: 56.61%;

                        @include mq(md) {
                            max-width: 100%;
                            margin-bottom: 35px;
                        }
                    }

                    &--img {
                        max-width: 38.46%;

                        @include mq(md) {
                            max-width: 100%;
                        }
                    }
                }
            }

            p {
                font-size: 1.6rem;
                line-height: 32px;
                letter-spacing: 0.05em;
                color: #393327;

                &.c-txt__2rem {
                    font-size: 2rem;
                    line-height: 40px;
                    color: #707070;

                    @include mq(lg) {
                        font-size: 1.6rem;
                        line-height: 26px;
                    }

                    &.mb0 {
                        margin-bottom: 0;
                    }

                    &.mb20 {
                        margin-bottom: 20px;
                    }
                }

                @include mq(md) {
                    line-height: 26px;
                }

                &:not(:last-child) {
                    margin-bottom: 100px;

                    @include mq(lg) {
                        margin-bottom: 50px;
                    }

                    @include mq(md) {
                        margin-bottom: 35px;
                    }
                }

                &.fs2rem {
                    font-size: 2rem;
                    line-height: 40px;
                    letter-spacing: 0;

                    @include mq(lg) {
                        font-size: 1.8rem;
                        line-height: 28px;
                    }

                    @include mq(md) {
                        font-size: 1.6rem;
                        line-height: 26px;
                    }
                }
            }

            &--text {
                width: 100%;
                max-width: calc(62.3% - 30px);

                @include mq(md) {
                    max-width: 100%;
                    margin-bottom: 35px;
                }
            }

            &--img {
                width: 100%;
                max-width: 37.7%;

                @include mq(md) {
                    max-width: 100%;
                }

                img {
                    @include mq(md) {
                        width: 100%;
                    }
                }
            }
        }

        &__block {
            @include fs;
            margin-bottom: 200px;

            @include mq(xl) {
                margin-bottom: 100px;
            }

            @include mq(lg) {
                margin-bottom: 80px;
            }

            &--item {
                width: 49%;
                margin-bottom: 20px;

                &:not(:last-child) {
                    @include mq(md) {
                        margin-bottom: 35px;
                    }
                }

                @include mq(md) {
                    width: 100%;
                    margin-bottom: 0;
                }

                p {
                    font-size: 1.6rem;
                    line-height: 32px;
                    letter-spacing: 0.05em;
                    @include yugothic-font;
                }
            }

            &--img {
                text-align: center;
                padding: 45px 0;
            }

            &--center {
                @include fc;
                margin-bottom: 50px;

                @include mq(lg) {
                    margin-bottom: 35px;
                }
            }

            &--circle {
                width: 96.85px;
                height: 96.85px;
                background: #658185;
                border-radius: 50%;
                @include fcc;
                color: #fff;
                @include yumincho-font;
                margin: 0 15px 0 3vw;

                @media screen and (max-width: 576px) {
                    margin-bottom: 30px;
                }
            }

            &--list {
                font-size: 1.8rem;
                line-height: 32px;
                color: #878585;

                @include mq(lg) {
                    font-size: 1.6rem;
                    line-height: 28px;
                }

                @media screen and (max-width: 576px) {
                    margin-bottom: 50px;

                    li {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        &__block2 {
            @include f;
            margin-bottom: 50px;

            @include mq(lg) {
                margin-bottom: 40px;
            }

            &.mb100 {
                margin-bottom: 100px;

                @include mq(lg) {
                    margin-bottom: 65px;
                }
            }

            &.p25 {
                .c-para1__block2 {
                    &--left,
                    &--right {
                        padding: 0 15px;
                        @include mq(md) {
                            padding: 0;
                        }
                    }
                }
            }

            &.reverse {
                flex-direction: row-reverse;

                &.mb92 {
                    margin-bottom: 92px;

                    @include mq(lg) {
                        margin-bottom: 65px;
                    }

                    @include mq(md) {
                        margin-bottom: 35px;
                    }
                }

                .c-para1__block2 {
                    &--left {
                        h4 {
                            margin-left: 20px;

                            @include mq(md) {
                                margin-left: 0;
                            }
                        }
                        > p {
                            margin-left: auto;

                            @include mq(md) {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }

            &--left,
            &--right {
                width: 50%;

                .c-btn__skeleton {
                    @include mq(xl) {
                        max-width: calc(100% - 50px);
                    }
                }

                &:not(:last-child) {
                    @include mq(md) {
                        margin-bottom: 35px;

                        @include mq(md) {
                            margin-bottom: 15px;
                        }
                    }
                }

                @include mq(md) {
                    width: 100%;
                }

                &.p0 {
                    figure {
                        padding: 0;
                    }
                }

                ul {
                    li {
                        &:not(:last-child) {
                            margin-bottom: 95px;

                            @include mq(lg) {
                                margin-bottom: 45px;
                            }

                            @include mq(md) {
                                margin-bottom: 30px;
                            }
                        }
                        h4 {
                            margin-bottom: 15px;

                            @include mq(md) {
                                margin-bottom: 10px;
                            }

                            strong {
                                font-weight: 600;
                                @include yumincho-font;
                                font-size: 2.4rem;
                                letter-spacing: 0.05em;
                                line-height: 30px;
                                display: block;

                                @include mq(lg) {
                                    font-size: 1.8rem;
                                    line-height: 28px;
                                }
                            }

                            span {
                                display: block;
                                color: #4fa9be;
                                font-weight: 500;
                                letter-spacing: 0.05em;
                                font-size: 2rem;
                                margin-top: 5px;

                                @include mq(lg) {
                                    font-size: 1.8rem;
                                }
                            }
                        }

                        p {
                            letter-spacing: 0.05em;

                            @include mq(lg) {
                                line-height: 26px;
                            }
                        }
                    }
                }

                > figure {
                    text-align: center;
                    padding: 0 25px;

                    @include mq(md) {
                        padding: 0;
                    }

                    img {
                        max-width: 100%;
                    }
                }

                > p {
                    width: calc(100% - 20px);

                    @include mq(md) {
                        width: 100%;
                    }
                }

                > h4 {
                    font-size: 3rem;
                    @include yumincho-font;
                    line-height: 39px;
                    color: #393327;
                    margin-bottom: 30px;

                    @include mq(lg) {
                        font-size: 2.4rem;
                        line-height: 32px;
                        margin-bottom: 20px;
                    }

                    @include mq(md) {
                        font-size: 1.8rem;
                        line-height: 28px;
                    }
                }

                .c-title__solid {
                    strong {
                        font-size: 3rem;
                        line-height: 39px;

                        @include mq(lg) {
                            font-size: 2rem;
                            line-height: 32px;
                        }
                    }
                }
            }

            > figure {
                text-align: center;
            }
        }

        &__btn {
            @include fcc;
        }

        &__textbox {
            padding: 0 75px;
            margin-bottom: 70px;

            @include mq(lg) {
                padding: 0 30px;
                margin-bottom: 35px;
            }

            @include mq(md) {
                padding: 0 15px;
            }

            @include mq(sm) {
                padding: 0;
            }

            &--border {
                background: #fff;
                border: 1px solid #707070;
                padding: 27px;

                @include mq(md) {
                    padding: 15px;
                }

                h3,
                p {
                    font-size: 2rem;
                    @include yumincho-font;
                    line-height: 35px;
                    color: #7f9195 !important;

                    &.tac {
                        text-align: center;
                    }
                }

                h3 {
                    margin-bottom: 0;
                }
            }

            &.mb0 {
                margin-bottom: 0;
            }

            h3,
            p {
                font-size: 2rem;
                line-height: 40px;

                @include mq(lg) {
                    font-size: 1.6rem;
                }

                > span {
                    font-weight: 600;
                    color: #b40404;
                }

                &.tac {
                    text-align: center;
                }

                &.mb35 {
                    margin-bottom: 35px;
                }
            }

            > h3 {
                color: #4fa9be;
                margin-bottom: 35px;

                @include mq(md) {
                    margin-bottom: 20px;
                }

                &.mb0 {
                    margin-bottom: 0;
                }
            }

            p {
                color: #707070;

                > strong {
                    z-index: 1;
                    position: relative;
                    color: #707070;

                    @media screen and (max-width: 720px) {
                        background: #d7ebf9;
                    }

                    &::before {
                        content: '';
                        width: 100%;
                        height: 45px;
                        z-index: -1;
                        background: #d7ebf9;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        @media screen and (max-width: 720px) {
                            display: none;
                        }
                    }
                }
            }
        }

        &__txt {
            font-size: 2rem;
            line-height: 40px;
            color: #707070;
            margin-bottom: 70px;

            @include mq(lg) {
                font-size: 1.8rem;
                line-height: 28px;
                margin-bottom: 40px;
            }

            @include mq(md) {
                font-size: 1.6rem;
                line-height: 26px;
                margin-bottom: 25px;
            }
        }

        &__img {
            padding-bottom: 127px;
            position: relative;
            z-index: 1;

            @include mq(md) {
                padding-bottom: 0;
            }

            figure {
                width: 58.38%;
                box-shadow: 10px 10px 6px rgba($color: #000000, $alpha: 0.16);

                @include mq(md) {
                    width: 100%;
                }

                &:not(:last-child) {
                    @include mq(md) {
                        margin-bottom: 35px;
                    }
                }

                &:nth-child(2) {
                    position: absolute;
                    z-index: 2;
                    right: 0;
                    bottom: 0;

                    @include mq(md) {
                        position: unset;
                    }
                }

                img {
                    width: 100%;
                }
            }
        }

        .mb150 {
            margin-bottom: 150px;

            @include mq(lg) {
                margin-bottom: 75px;
            }
        }
    }

    &2 {
        h3 {
            color: #4fa9be;
            font-size: 2rem;
            line-height: 32px;
            letter-spacing: 0.05em;
            font-weight: 600;
            margin-bottom: 20px;

            @include mq(lg) {
                font-size: 1.8rem;
                line-height: 28px;
            }
        }

        p {
            font-size: 1.6rem;
            line-height: 32px;
            @include yugothic-font;
            margin-bottom: 40px;
            color: #393327;

            @include mq(lg) {
                line-height: 26px;
                margin-bottom: 25px;
            }

            &.mb15 {
                margin-bottom: 15px;
            }

            &.mb100 {
                margin-bottom: 100px;

                @include mq(lg) {
                    margin-bottom: 50px;
                }
            }
        }

        &__img {
            text-align: center;
            margin-bottom: 80px;

            @include mq(lg) {
                margin-bottom: 40px;
            }
        }

        &__block {
            background: url(../images/design1-1/bg-article2-block.png) no-repeat right;
            height: 402px;
            padding: 10px 0;

            @include mq(lg) {
                padding: 25px 15px;
            }

            @include mq(sm) {
                height: auto;
                background-size: cover;
            }

            ul {
                @include mq(lg) {
                    padding: 25px;
                    border-radius: 10px;
                    background: rgba($color: #fff, $alpha: 0.5);
                }
                li {
                    font-size: 1.6rem;
                    line-height: 32px;
                    letter-spacing: 0.05em;
                    @include yugothic-font;

                    @include mq(lg) {
                        line-height: 26px;
                    }
                }
            }
        }
    }

    &3 {
        &:not(:last-child) {
            margin-bottom: 180px;

            @include mq(xl) {
                margin-bottom: 100px;
            }

            @include mq(lg) {
                margin-bottom: 65px;
            }
        }

        &.mb150 {
            margin-bottom: 150px;

            @include mq(xl) {
                margin-bottom: 100px;
            }

            @include mq(lg) {
                margin-bottom: 65px;
            }

            @include mq(md) {
                margin-bottom: 35px;
            }
        }

        > h3 {
            @include meiryo-font;
            font-size: 2.4rem;
            line-height: 36px;
            color: #fff;
            padding: 20px 50px;
            border-radius: 50px;
            background: #878585;
            width: 100%;
            text-align: left;
            font-weight: 400;
            margin-bottom: 40px;

            @include mq(xl) {
                padding: 10px 25px;
                font-size: 2rem;
                line-height: 32px;
            }

            @include mq(lg) {
                padding: 5px 15px;
                font-size: 1.8rem;
                margin-bottom: 25px;
            }
        }

        &__row {
            @include fs;

            &--left,
            &--right {
                width: 50%;

                @include mq(md) {
                    width: 100%;
                }
            }

            &--left {
                padding: 0 20px;

                @include mq(xl) {
                    padding: 0 10px;
                }

                @include mq(md) {
                    padding: 0;
                    margin-bottom: 35px;
                }

                &.pl0 {
                    padding-left: 0;
                }

                &.mb120 {
                    margin-bottom: 120px;

                    @include mq(lg) {
                        margin-bottom: 60px;
                    }

                    @include mq(md) {
                        margin-bottom: 35px;
                    }
                }

                h4 {
                    @include yumincho-font;
                    font-weight: 600;
                    font-size: 3rem;
                    line-height: 38px;
                    margin-bottom: 25px;
                    color: #878585;

                    @include mq(lg) {
                        font-size: 2.4rem;
                        line-height: 32px;
                        margin-bottom: 20px;
                    }

                    @include mq(md) {
                        font-size: 1.8rem;
                        line-height: 28px;
                    }
                }

                > p {
                    font-size: 1.8rem;
                    line-height: 32px;
                    margin-bottom: 45px;
                    color: #707070;

                    &.mincho-font {
                        @include yumincho-font;
                    }

                    &.fsz16rem {
                        font-size: 1.6rem;
                    }

                    @include mq(lg) {
                        font-size: 1.6rem;
                        line-height: 26px;
                        margin-bottom: 30px;
                    }
                }

                dl {
                    > dt {
                        display: none !important;
                    }

                    > dt,
                    dd {
                        width: 100%;
                        @include f;
                        color: #878585;

                        a {
                            color: #cf0707;
                        }

                        &:not(:last-child) {
                            margin-bottom: 45px;

                            @include mq(lg) {
                                margin-bottom: 25px;
                            }
                        }

                        div {
                            &:first-child {
                                width: 96.85px;
                                height: 96.85px;
                                background: #658185;
                                border-radius: 50%;
                                color: #fff;
                                @include yumincho-font;
                                letter-spacing: 0.05em;
                                @include fcc;
                                margin-right: 30px;

                                @include mq(xl) {
                                    font-size: 1.4rem;
                                    width: 80px;
                                    height: 80px;
                                    margin-right: 20px;
                                }

                                &.pink-bg {
                                    background: #fb8da0;
                                }
                            }

                            &.txt {
                                max-width: calc(100% - 130px);

                                @include mq(xl) {
                                    width: calc(100% - 100px);
                                    font-size: 1.4rem;
                                    line-height: 22px;
                                }
                            }
                        }
                    }
                }
            }

            &--right {
                text-align: center;

                img {
                    max-width: 100%;

                    @include mq(md) {
                        width: 100%;
                    }
                }
            }

            &--flex {
                @include fs;
                padding: 0 25px;

                @media screen and (max-width: 1300px) {
                    padding: 0;
                }

                &.p0 {
                    padding: 0;
                }

                &:not(:last-child) {
                    margin-bottom: 45px;

                    @include mq(md) {
                        margin-bottom: 25px;
                    }
                }
            }
        }
    }
}

.c-group {
    &__img {
        @include fsc;
        margin-bottom: 50px;

        figure {
            width: 100%;

            &:not(:last-child) {
                @media screen and (max-width: 576px) {
                    margin-bottom: 30px;
                }
            }

            &:first-child {
                max-width: 28.53%;

                @media screen and (max-width: 576px) {
                    max-width: 100%;
                }
            }

            &:nth-child(2) {
                max-width: 30.76%;

                @media screen and (max-width: 576px) {
                    max-width: 100%;
                }
            }

            &:nth-child(3) {
                max-width: 33.53%;

                @media screen and (max-width: 576px) {
                    max-width: 100%;
                }
            }

            img {
                width: 100%;
            }
        }
    }
}

.c-box {
    &__cover {
        background: #f2f1f1;
        padding: 40px;
        margin-bottom: 55px;

        @include mq(lg) {
            margin-bottom: 35px;
            padding: 25px;
        }

        @include mq(md) {
            padding: 15px;
        }
    }
}

.blue-link {
    font-weight: 600;
    color: #4fa9be !important;
}

.overflow-x {
    @include mq(md) {
        overflow-x: scroll;
    }
}

.c-decor__waterseal {
    position: relative;
    padding-bottom: 75px;

    @include mq(md) {
        padding-bottom: 0;
    }

    &--content {
        width: calc(71.54% - 70px);

        @include mq(md) {
            width: 100%;
        }
    }

    &--img {
        width: 28.46%;
        position: absolute;
        bottom: 0;
        right: 0;

        @include mq(md) {
            width: 100%;
            max-width: 370px;
            margin: 0 auto;
            position: unset;
        }

        img {
            width: 100%;
        }
    }
}

.c-video {
    text-align: center;
    margin-bottom: 65px;

    iframe {
        max-width: 100%;
    }
}

.c-pagination {
    ul {
        @include fcc;

        li {
            width: 30px;
            height: 30px;
            @include fcc;
            border: 1px solid #4fa9be;
            background: #fff;
            margin: 0 5px;

            @include mq(md) {
                margin: 0 3px;
            }

            &.dotted {
                border: none;
                width: auto;
                background: none;
            }

            &.is-active {
                background: #4fa9be;

                a {
                    color: #fff;
                }
            }

            &:first-child,
            &:last-child {
                width: auto;
                padding: 0 15px;

                @include mq(md) {
                    padding: 0 5px;
                }
            }

            a,
            span {
                text-transform: uppercase;
                font-size: 1.6rem;
                color: #4fa9be;
            }
        }
    }
}

.c-policy {
    max-height: 285px;
    overflow-y: scroll;
    margin-bottom: 45px;

    // &::-webkit-scrollbar {
    //     width: 5px;
    //     background: #a8a5a5;
    // }
}

.c-confirm__box {
    max-width: 64.53%;
    margin: 0 auto 50px;
    background: #fff;
    padding: 41px 110px;
    display: block;

    @include mq(xl) {
        max-width: 80%;
    }

    @include mq(lg) {
        padding: 20px 40px;
    }

    @include mq(md) {
        max-width: 100%;
        padding: 15px;
    }

    input {
        position: absolute;
        z-index: -999999999;

        &:checked + span {
            &::after {
                content: '';
                width: 19px;
                height: 9px;
                border-left: 3px solid #fff;
                border-bottom: 3px solid #fff;
                position: absolute;
                left: 5px;
                top: 30%;
                transform: translateY(-50%) rotate(-45deg);

                @include mq(lg) {
                    width: 10px;
                    height: 6px;
                    border-width: 2px;
                }
            }

            &::before {
                background: #4fa9be;
            }
        }
    }

    span {
        span {
            position: relative;
            padding-left: 58px;
            display: block;
            line-height: 44px;
            font-size: 1.8rem;

            @include mq(xl) {
                font-size: 1.6rem;
                line-height: 32px;
            }

            @include mq(lg) {
                padding-left: 35px;
            }

            &::before {
                content: '';
                width: 30px;
                height: 30px;
                border: 1px solid #707070;
                position: absolute;
                left: 0;
                transform: translateY(-50%);
                top: 33%;

                @include mq(lg) {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

.close_btn {
    background: #a8d7e2;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 0;
    width: 50px;
    height: 50px;
    z-index: 99999;
    cursor: pointer;
}
