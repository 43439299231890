form {
    table {
        width: 100%;
        margin-bottom: 35px;
        table-layout: fixed;

        tr {
            &:not(:last-child) {
                th,
                td {
                    @include mq(md) {
                        border-bottom: none;
                    }
                }
            }

            th,
            td {
                border: 1px solid #ccc;

                @include mq(md) {
                    width: auto;
                    display: block;
                }

                textarea,
                select,
                input[type='text'],
                input[type='tel'],
                input[type='email'] {
                    position: relative;
                    overflow: hidden;
                    padding: 5px 10px;
                    background: #f9f9f9 !important;
                    margin: 5px 5px 5px 0;
                    font-size: 1.3rem;
                    box-shadow: inset 1px 1px 1px rgba(#000, 0.1);
                    -moz-box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
                    -webkit-box-shadow: inset 1px 1px 1px rgba(#000, 0.1);
                    border: 1px solid #ccc !important;
                    border-radius: 4px;
                    outline: 0;
                    -webkit-appearance: none;

                    @include mq(md) {
                        padding: 0 10px;
                        margin: 5px 0;
                    }
                }

                input[type='text'],
                input[type='tel'] {
                    @include mq(md) {
                        width: 100%;
                    }
                }

                input[type='radio'] {
                    margin-right: 0.5em;
                    margin-left: 0.5em;
                    width: auto;
                    display: inline-block;
                    appearance: none !important;
                    -webkit-appearance: none !important;
                    position: absolute;
                    z-index: -999999999;

                    &:checked + span {
                        &::before {
                            border-color: #0075ff;
                        }

                        &::after {
                            content: '';
                            width: 8px;
                            height: 8px;
                            background: #0075ff;
                            border-radius: 50%;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 3px;
                        }
                    }

                    & + span {
                        padding-left: 20px;
                        position: relative;
                        margin-right: 20px;

                        @include mq(lg) {
                            margin-right: 10px;
                        }

                        &::before {
                            content: '';
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            border: 1px solid;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }

                input[type='radio']:checked + label {
                    background: unset;
                }

                textarea {
                    width: 94%;
                    height: 142px;

                    @include mq(md) {
                        width: 100%;
                    }
                }
            }

            th {
                text-align: left;
                vertical-align: middle;
                width: 42%;
                padding: 10px 10px;
                background-color: rgba(0, 169, 172, 0.1);
                font-weight: 400;

                @include mq(md) {
                    width: auto;
                    display: block;
                    padding: 5px;
                }

                .hissu {
                    display: inline-block;
                    color: red;
                    font-size: 1.2rem;
                }
            }

            td {
                text-align: left;
                padding: 8px 15px;
                background-color: #fff;
                vertical-align: middle;
                // @include ffc;

                @include mq(md) {
                    padding: 5px;
                }

                fieldset {
                    &.column {
                        @include f;
                        flex-direction: column;

                        span {
                            margin-left: 0 !important;
                        }
                    }
                }
            }
        }
    }
}

#formnote {
    margin-bottom: 50px;

    @include mq(lg) {
        margin-bottom: 35px;
    }
}

.formError {
    &Content {
        color: #ee0101 !important;
        background: #fff !important;
        border: 1px solid #ee0101 !important;
        box-shadow: 0 0 4px #000 !important;
    }

    &Arrow {
        margin: -1px 0 0 13px !important;
    }

    .line {
        &1,
        &2 {
            background: none !important;
        }

        &1,
        &2,
        &3,
        &4,
        &5,
        &6,
        &7,
        &8,
        &9,
        &10 {
            border: none !important;
            box-shadow: none !important;
        }
    }
}
