.p-access {
    &1 {
        &__inner {
            padding: 70px 0 40px;
            position: relative;

            &--decor {
                @extend .p-pay1__inner--decor;
            }

            &::before {
                content: '';
                z-index: 2;
                width: 100%;
                height: calc(12vw + 8px);
                background: url(../images/design1-3/wave-white.svg) no-repeat;
                background-size: cover;
                position: absolute;
                left: 0;
                bottom: calc(100% - 1px);
            }
        }
    }

    &2 {
        padding: 40px 0 100px;

        @include mq(lg) {
            padding: 65px 0;
        }
    }
}
