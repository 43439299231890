.p-pedia {
    &1 {
        background: #ebf7fa;
        padding-bottom: 70px;

        &__inner {
            padding: 70px 0 75px;
            position: relative;

            &--decor {
                @extend .p-pay1__inner--decor;
            }

            &::before {
                content: '';
                z-index: 2;
                width: 100%;
                height: calc(12vw + 8px);
                background: url(../images/design1-1/wave.svg) no-repeat;
                background-size: cover;
                position: absolute;
                left: 0;

                bottom: calc(100% - 1px);
            }
        }
    }
}
