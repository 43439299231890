.p-pay {
    &1 {
        background: #ebf7fa;

        &__inner {
            padding: 70px 0 90px;
            position: relative;

            @include mq(lg) {
                padding: 65px 0;
            }

            &--decor {
                position: absolute;
                left: 50%;
                bottom: calc(100% + 36px);
                transform: translateX(-50%);
                z-index: 3;
                width: 19.23%;
                max-width: 250px;

                @media screen and (max-width: 1600px) {
                    width: 15%;
                    bottom: calc(100% + 18px);
                }

                @include mq(md) {
                    width: 120px;
                    bottom: 99%;
                }

                > img {
                    width: 100%;
                }
            }

            &::before {
                content: '';
                z-index: 2;
                width: 100%;
                height: calc(12vw + 8px);
                background: url(../images/design1-1/wave.svg) no-repeat;
                background-size: cover;
                position: absolute;
                left: 0;

                bottom: calc(100% - 1px);
            }
        }
    }

    &2 {
        padding: 40px 0 65px;
    }

    &3 {
        padding: 100px 0;
        background: #ebf7fa;

        @include mq(lg) {
            padding: 65px 0;
        }
    }
}
