/* *****************************
 * Common
 **************************** */

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    transition: all 0.3s ease-out;
    &:hover {
        opacity: 0.7;
    }
}

img {
    max-width: 100%;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    line-height: 32px;
    position: relative;
    color: #393327;
    background: #fff;
}

html,
body {
    // overflow-x: hidden;
}

a,
abbr,
address,
article,
aside,
audio,
b,
blockquote,
button,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
input,
kbd,
label,
legend,
li,
main,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
textarea,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
    font-smoothing: antialiased;
    appearance: none;
    font-display: swap;
    font-family: '游ゴシック', YuGothic, YuGothicM, 'Hiragino Kaku Gothic ProN', メイリオ, Meiryo,
        sans-serif;
}

input[type='submit'],
button {
    appearance: none;
}

.container {
    padding: 0 15px;
    margin: 0 auto;
}

.l-container {
    max-width: 1230px;
    padding: 0 15px;
    margin: 0 auto;

    &.non-padding-sp {
        @include mq(md) {
            padding: 0;
        }
    }

    &__1300px {
        @extend .container;
        max-width: 1330px;
    }
}

// Class Hide/Unhide

.pc-only {
    display: block;

    @include mq(md) {
        display: none;
    }
}
.sp-only {
    display: none;

    @include mq(md) {
        display: block;
        width: 100%;
    }
}

// Fixed class

.is-fixed {
    overflow-y: hidden;
}

// Mixin flexbox

@mixin f {
    display: flex;
    flex-wrap: wrap;
}

@mixin fs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@mixin fsc {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

@mixin fc {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@mixin fe {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

@mixin fcc {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@mixin ffc {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

@mixin ffe {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

@mixin fse {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
}

@mixin fec {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}

@mixin yumincho-font {
    font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
}

@mixin yugothic-font {
    font-family: '游ゴシック', '游ゴシック体', 'Yu Gothic Medium', YuGothic, 'ヒラギノ角ゴ ProN',
        'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}

@mixin hobo-font {
    font-family: hobo-std, sans-serif;
    font-weight: 400;
    font-style: normal;
}

@mixin meiryo-font {
    font-family: Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}
