/* *****************************
 * Main visual
 **************************** */

.p-mv {
    position: relative;
    z-index: 1;

    @include mq(md) {
        margin-top: 160px;
    }

    &__bg {
        position: relative;
        z-index: -1;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            background: #6a6a6a;
            opacity: 0.53;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }

        img {
            width: 100%;

            @include mq(xl) {
                height: 50vw;
                object-fit: cover;
            }

            @include mq(md) {
                height: 70vw;
            }
        }
    }

    h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;

        @include mq(md) {
            top: 40%;
        }

        strong {
            @include yumincho-font;
            color: #fff;
            font-size: 6rem;
            letter-spacing: 0.05em;
            line-height: 44px;
            display: block;
            text-align: center;
            margin-bottom: 15px;

            @include mq(xl) {
                font-size: 4.2rem;
                line-height: 38px;
            }

            @include mq(lg) {
                font-size: 3rem;
                line-height: 30px;
                margin-bottom: 5px;
            }
        }

        span {
            @include yugothic-font;
            color: #fff;
            font-size: 2rem;
            letter-spacing: 0.05em;
            line-height: 32px;
            font-weight: 500;
            display: block;
            text-align: center;

            @include mq(xl) {
                font-size: 1.8rem;
                line-height: 28px;
            }
        }
    }
}
