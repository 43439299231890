/* *****************************
 * Header
 **************************** */

header {
    background: rgba($color: #f2fcff, $alpha: 0.8) !important;
    h1 {
        padding: 17px 0;
    }
}
