.p-decay {
    &1 {
        &__inner {
            padding: 70px 0;
            position: relative;

            &--decor {
                @extend .p-pay1__inner--decor;
            }

            &::before {
                content: '';
                z-index: 2;
                width: 100%;
                height: calc(12vw + 8px);
                background: url(../images/design1-3/wave-white.svg) no-repeat;
                background-size: cover;
                position: absolute;
                left: 0;

                bottom: calc(100% - 1px);
            }

            section {
                .l-container__1300px {
                    section {
                        > figure {
                            text-align: center;
                            margin-bottom: 37px;

                            &.mb60 {
                                margin-bottom: 60px;
                            }

                            img {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
