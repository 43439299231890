/* *****************************
 * Button
 **************************** */

.c-btn {
    &__call {
        background: #878585;
        font-size: 4.5rem;
        line-height: 137px;
        display: block;
        max-width: 585px;
        width: 100%;
        margin: 0 auto;
        color: #fff !important;
        @include yumincho-font;
        text-align: center;
        font-weight: 600;

        @include mq(lg) {
            line-height: 100px;
            max-width: 400px;
            font-size: 3.6rem;
        }

        @include mq(md) {
            line-height: 85px;
            font-size: 3rem;
        }

        &:hover {
            opacity: 1;

            &::before {
                animation: phone-shake 1s ease-in-out infinite;
            }
        }

        &::before {
            content: '';
            display: inline-block;
            margin-right: 50px;
            vertical-align: -20px;
            width: 45.89px;
            height: 63.67px;
            background: url(../images/common/svg/icon-phone.svg) no-repeat;
            background-size: cover;

            @include mq(lg) {
                width: 32px;
                height: 45px;
                vertical-align: -12px;
                margin-right: 20px;
            }

            @include mq(md) {
                width: 25px;
                height: 35px;
                vertical-align: -8px;
                margin-right: 15px;
            }
        }
    }

    &__skeleton {
        max-width: 543px;
        width: 100%;
        margin: 0 auto;
        height: 129px;
        border: 1px solid #707070;
        text-align: center;
        color: #707070 !important;
        background: #fff;
        font-size: 2rem;
        @include fcc;

        @include mq(lg) {
            height: 100px;
        }

        @include mq(md) {
            height: 85px;
            font-size: 1.6rem;
        }

        &.mb70 {
            margin-bottom: 70px;

            @include mq(lg) {
                margin-bottom: 45px;
            }
        }

        &.two-line {
            height: 131px;
            line-height: 26px;
            @include fcc;
            padding: 30px;
            flex-direction: column;

            @include mq(lg) {
                height: 102px;
                padding: 9px;
            }

            @include mq(md) {
                height: 87px;
            }
            > span {
                display: block;
                line-height: 33px;
            }
        }

        &--base {
            width: 100%;
            font-size: 2rem;
            line-height: 26px;
            padding: 25px;
            display: block;
            text-align: center;
            border: 1px solid #707070;
            background: #fff;
            color: #707070 !important;

            @include mq(lg) {
                font-size: 1.8rem;
                padding: 15px;
            }

            @include mq(md) {
                font-size: 1.6rem;
                padding: 10px 15px;
            }
        }

        &--short {
            @extend .c-btn__skeleton--base;
            max-width: 276px;
        }

        &--long {
            @extend .c-btn__skeleton--base;
            max-width: calc(100% - 330px);
            margin: 0 auto;

            @include mq(md) {
                max-width: calc(100% - 100px);
            }

            @include mq(sm) {
                max-width: 100%;
            }
        }
    }

    &__qr {
        padding: 12px 46px;
        @include fsc;
        max-width: 493px;
        margin: 0 auto;
        border: 1px solid #707070;

        @include mq(lg) {
            padding: 10px 15px;
        }

        &::after {
            content: '';
            width: 94px;
            height: 94px;
            background: url(../images/design1-10/img-qr.svg);
            background-size: cover;
            display: block;
            vertical-align: 0;
        }

        span {
            font-size: 1.6rem;
            line-height: 26px;
            display: block;
            width: 64%;
            text-align: center;
        }
    }

    &__submit {
        max-width: 343px;
        width: 100%;
        margin: 0 auto;
        display: block;
        border: 1px solid #707070;
        font-size: 2rem;
        line-height: 26px;
        @include yumincho-font;
        text-align: center;
        padding: 20px;
        background: #fff;
        border-radius: 50px;
        font-weight: 600;
        transition: all 0.3s;

        @include mq(md) {
            padding: 10px;
            font-size: 1.8rem;
        }

        &:hover {
            opacity: 0.7;
        }
    }
}

@keyframes phone-shake {
    15%,
    85% {
        transform: rotate(0deg);
    }
    35%,
    45%,
    55%,
    65% {
        transform: rotate(10deg);
    }
    30%,
    40%,
    50%,
    60%,
    75% {
        transform: rotate(-10deg);
    }
}
