@charset "utf-8";
/* *****************************
 *
 * Import file to Scss or Sass.
 *
 **************************** */

//Module folder
@import 'module/_setup';
@import "module/_common.scss";
@import "module/_setup.scss";

//Layout folder
@import "layout/_footer.scss";
@import "layout/_header.scss";

// Object folder
@import "object/_article.scss";
@import "object/_button.scss";
@import "object/_form.scss";
@import "object/_list.scss";
@import "object/_mv.scss";
@import "object/_other.scss";
@import "object/_slider.scss";
@import "object/_table.scss";
@import "object/_text.scss";
@import "object/_title.scss";

// Page folder
@import "page/_access.scss";
@import "page/_aesthe.scss";
@import "page/_anti.scss";
@import "page/_backup.scss";
@import "page/_contact.scss";
@import "page/_correct.scss";
@import "page/_decay.scss";
@import "page/_denture.scss";
@import "page/_feature.scss";
@import "page/_implant.scss";
@import "page/_information.scss";
@import "page/_pay.scss";
@import "page/_pedia.scss";
@import "page/_top.scss";
@import "page/_welcome.scss";
@import "page/_white.scss";
