@charset "UTF-8";
/* *****************************
 *
 * Import file to Scss or Sass.
 *
 **************************** */
/* *****************************
 * Setup
 **************************** */
/* *****************************
 * Common
 **************************** */
li {
  list-style-type: none;
}

a {
  text-decoration: none;
  transition: all 0.3s ease-out;
}

a:hover {
  opacity: 0.7;
}

img {
  max-width: 100%;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  line-height: 32px;
  position: relative;
  color: #393327;
  background: #fff;
}

a,
abbr,
address,
article,
aside,
audio,
b,
blockquote,
button,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
input,
kbd,
label,
legend,
li,
main,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
textarea,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  font-smoothing: antialiased;
  appearance: none;
  font-display: swap;
  font-family: '游ゴシック', YuGothic, YuGothicM, 'Hiragino Kaku Gothic ProN', メイリオ, Meiryo, sans-serif;
}

input[type='submit'],
button {
  appearance: none;
}

.container, .l-container__1300px {
  padding: 0 15px;
  margin: 0 auto;
}

.l-container, .c-list__note {
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .l-container.non-padding-sp, .non-padding-sp.c-list__note {
    padding: 0;
  }
}

.l-container__1300px {
  max-width: 1330px;
}

.pc-only {
  display: block;
}

@media screen and (max-width: 768px) {
  .pc-only {
    display: none;
  }
}

.sp-only {
  display: none;
}

@media screen and (max-width: 768px) {
  .sp-only {
    display: block;
    width: 100%;
  }
}

.is-fixed {
  overflow-y: hidden;
}

/* *****************************
 * Setup
 **************************** */
/* *****************************
 * Footer
 **************************** */
/* *****************************
 * Header
 **************************** */
header {
  background: rgba(242, 252, 255, 0.8) !important;
}

header h1 {
  padding: 17px 0;
}

/* *****************************
 * Article
 **************************** */
.c-article1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.c-article1__img {
  width: 100%;
  max-width: 300px;
}

@media screen and (max-width: 768px) {
  .c-article1__img {
    max-width: 100%;
  }
}

.c-article1__img img {
  width: 100%;
}

.c-article1__content {
  width: calc(100% - 330px);
}

@media screen and (max-width: 768px) {
  .c-article1__content {
    width: 100%;
    margin-bottom: 35px;
  }
}

.c-article1__content h4 {
  color: #4fa9be;
  font-size: 2rem;
  line-height: 26px;
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .c-article1__content h4 {
    font-size: 1.8rem;
  }
}

.c-article2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 25px;
}

.c-article2__img {
  width: 100%;
  max-width: 46.15%;
}

@media screen and (max-width: 768px) {
  .c-article2__img {
    max-width: 100%;
  }
}

.c-article2__img img {
  width: 100%;
}

.c-article2__content {
  width: 100%;
  max-width: calc(53.85% - 20px);
}

@media screen and (max-width: 768px) {
  .c-article2__content {
    max-width: 100%;
    margin-bottom: 35px;
  }
}

.c-article2__content h4 {
  font-size: 2rem;
  letter-spacing: 0.05em;
  line-height: 26px;
  margin-bottom: 20px;
  color: #7f9195;
}

@media screen and (max-width: 992px) {
  .c-article2__content h4 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
}

.c-article2__content p {
  letter-spacing: 0.05em;
}

@media screen and (max-width: 992px) {
  .c-article2__content p {
    line-height: 26px;
  }
}

.c-article3 {
  margin-bottom: 140px;
}

@media screen and (max-width: 1200px) {
  .c-article3 {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 992px) {
  .c-article3 {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 768px) {
  .c-article3 {
    margin-bottom: 35px;
  }
}

.c-article3__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .c-article3__content {
    margin-bottom: 35px;
  }
}

.c-article3__content--child {
  width: 48%;
}

@media screen and (max-width: 768px) {
  .c-article3__content--child {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .c-article3__content--child:not(:last-child) {
    margin-bottom: 35px;
  }
}

.c-article3__content--child h4 {
  font-size: 3rem;
  line-height: 39px;
  margin-bottom: 35px;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  font-weight: 400;
}

@media screen and (max-width: 992px) {
  .c-article3__content--child h4 {
    font-size: 2.4rem;
    line-height: 32px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 768px) {
  .c-article3__content--child h4 {
    font-size: 1.8rem;
    line-height: 26px;
    margin-bottom: 15px;
  }
}

.c-article3__img {
  text-align: center;
}

.c-article3__img img {
  max-width: 100%;
  box-shadow: 10px 10px 6px rgba(0, 0, 0, 0.16);
}

.c-article4 {
  padding: 60px 30px 100px;
  background: #fff;
  margin-bottom: 100px;
}

@media screen and (max-width: 992px) {
  .c-article4 {
    margin-bottom: 65px;
    padding: 30px 15px;
  }
}

@media screen and (max-width: 768px) {
  .c-article4 {
    margin-bottom: 35px;
  }
}

.c-article4__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 45px;
}

.c-article4__row--content {
  width: 100%;
  max-width: 43.3%;
}

@media screen and (max-width: 768px) {
  .c-article4__row--content {
    max-width: 100%;
  }
}

.c-article4__row--content p {
  font-size: 1.8rem;
  line-height: 40px;
  margin-bottom: 40px;
}

@media screen and (max-width: 992px) {
  .c-article4__row--content p {
    font-size: 1.6rem;
    line-height: 32px;
    margin-bottom: 25px;
  }
}

.c-article4__row--content p strong {
  background: #a2cdd1;
  font-weight: 400;
}

.c-article4__row--img {
  max-width: 53.62%;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .c-article4__row--img {
    max-width: 100%;
    margin-bottom: 35px;
  }
}

.c-article4 figure {
  text-align: center;
}

/* *****************************
 * Button
 **************************** */
.c-btn__call {
  background: #878585;
  font-size: 4.5rem;
  line-height: 137px;
  display: block;
  max-width: 585px;
  width: 100%;
  margin: 0 auto;
  color: #fff !important;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  text-align: center;
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .c-btn__call {
    line-height: 100px;
    max-width: 400px;
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 768px) {
  .c-btn__call {
    line-height: 85px;
    font-size: 3rem;
  }
}

.c-btn__call:hover {
  opacity: 1;
}

.c-btn__call:hover::before {
  animation: phone-shake 1s ease-in-out infinite;
}

.c-btn__call::before {
  content: '';
  display: inline-block;
  margin-right: 50px;
  vertical-align: -20px;
  width: 45.89px;
  height: 63.67px;
  background: url(../images/common/svg/icon-phone.svg) no-repeat;
  background-size: cover;
}

@media screen and (max-width: 992px) {
  .c-btn__call::before {
    width: 32px;
    height: 45px;
    vertical-align: -12px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .c-btn__call::before {
    width: 25px;
    height: 35px;
    vertical-align: -8px;
    margin-right: 15px;
  }
}

.c-btn__skeleton {
  max-width: 543px;
  width: 100%;
  margin: 0 auto;
  height: 129px;
  border: 1px solid #707070;
  text-align: center;
  color: #707070 !important;
  background: #fff;
  font-size: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .c-btn__skeleton {
    height: 100px;
  }
}

@media screen and (max-width: 768px) {
  .c-btn__skeleton {
    height: 85px;
    font-size: 1.6rem;
  }
}

.c-btn__skeleton.mb70 {
  margin-bottom: 70px;
}

@media screen and (max-width: 992px) {
  .c-btn__skeleton.mb70 {
    margin-bottom: 45px;
  }
}

.c-btn__skeleton.two-line {
  height: 131px;
  line-height: 26px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 30px;
  flex-direction: column;
}

@media screen and (max-width: 992px) {
  .c-btn__skeleton.two-line {
    height: 102px;
    padding: 9px;
  }
}

@media screen and (max-width: 768px) {
  .c-btn__skeleton.two-line {
    height: 87px;
  }
}

.c-btn__skeleton.two-line > span {
  display: block;
  line-height: 33px;
}

.c-btn__skeleton--base, .c-btn__skeleton--short, .c-btn__skeleton--long {
  width: 100%;
  font-size: 2rem;
  line-height: 26px;
  padding: 25px;
  display: block;
  text-align: center;
  border: 1px solid #707070;
  background: #fff;
  color: #707070 !important;
}

@media screen and (max-width: 992px) {
  .c-btn__skeleton--base, .c-btn__skeleton--short, .c-btn__skeleton--long {
    font-size: 1.8rem;
    padding: 15px;
  }
}

@media screen and (max-width: 768px) {
  .c-btn__skeleton--base, .c-btn__skeleton--short, .c-btn__skeleton--long {
    font-size: 1.6rem;
    padding: 10px 15px;
  }
}

.c-btn__skeleton--short {
  max-width: 276px;
}

.c-btn__skeleton--long {
  max-width: calc(100% - 330px);
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .c-btn__skeleton--long {
    max-width: calc(100% - 100px);
  }
}

@media screen and (max-width: 480px) {
  .c-btn__skeleton--long {
    max-width: 100%;
  }
}

.c-btn__qr {
  padding: 12px 46px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 493px;
  margin: 0 auto;
  border: 1px solid #707070;
}

@media screen and (max-width: 992px) {
  .c-btn__qr {
    padding: 10px 15px;
  }
}

.c-btn__qr::after {
  content: '';
  width: 94px;
  height: 94px;
  background: url(../images/design1-10/img-qr.svg);
  background-size: cover;
  display: block;
  vertical-align: 0;
}

.c-btn__qr span {
  font-size: 1.6rem;
  line-height: 26px;
  display: block;
  width: 64%;
  text-align: center;
}

.c-btn__submit {
  max-width: 343px;
  width: 100%;
  margin: 0 auto;
  display: block;
  border: 1px solid #707070;
  font-size: 2rem;
  line-height: 26px;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  text-align: center;
  padding: 20px;
  background: #fff;
  border-radius: 50px;
  font-weight: 600;
  transition: all 0.3s;
}

@media screen and (max-width: 768px) {
  .c-btn__submit {
    padding: 10px;
    font-size: 1.8rem;
  }
}

.c-btn__submit:hover {
  opacity: 0.7;
}

@keyframes phone-shake {
  15%,
  85% {
    transform: rotate(0deg);
  }
  35%,
  45%,
  55%,
  65% {
    transform: rotate(10deg);
  }
  30%,
  40%,
  50%,
  60%,
  75% {
    transform: rotate(-10deg);
  }
}

form table {
  width: 100%;
  margin-bottom: 35px;
  table-layout: fixed;
}

@media screen and (max-width: 768px) {
  form table tr:not(:last-child) th,
  form table tr:not(:last-child) td {
    border-bottom: none;
  }
}

form table tr th,
form table tr td {
  border: 1px solid #ccc;
}

@media screen and (max-width: 768px) {
  form table tr th,
  form table tr td {
    width: auto;
    display: block;
  }
}

form table tr th textarea,
form table tr th select,
form table tr th input[type='text'],
form table tr th input[type='tel'],
form table tr th input[type='email'],
form table tr td textarea,
form table tr td select,
form table tr td input[type='text'],
form table tr td input[type='tel'],
form table tr td input[type='email'] {
  position: relative;
  overflow: hidden;
  padding: 5px 10px;
  background: #f9f9f9 !important;
  margin: 5px 5px 5px 0;
  font-size: 1.3rem;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc !important;
  border-radius: 4px;
  outline: 0;
  -webkit-appearance: none;
}

@media screen and (max-width: 768px) {
  form table tr th textarea,
  form table tr th select,
  form table tr th input[type='text'],
  form table tr th input[type='tel'],
  form table tr th input[type='email'],
  form table tr td textarea,
  form table tr td select,
  form table tr td input[type='text'],
  form table tr td input[type='tel'],
  form table tr td input[type='email'] {
    padding: 0 10px;
    margin: 5px 0;
  }
}

@media screen and (max-width: 768px) {
  form table tr th input[type='text'],
  form table tr th input[type='tel'],
  form table tr td input[type='text'],
  form table tr td input[type='tel'] {
    width: 100%;
  }
}

form table tr th input[type='radio'],
form table tr td input[type='radio'] {
  margin-right: 0.5em;
  margin-left: 0.5em;
  width: auto;
  display: inline-block;
  appearance: none !important;
  -webkit-appearance: none !important;
  position: absolute;
  z-index: -999999999;
}

form table tr th input[type='radio']:checked + span::before,
form table tr td input[type='radio']:checked + span::before {
  border-color: #0075ff;
}

form table tr th input[type='radio']:checked + span::after,
form table tr td input[type='radio']:checked + span::after {
  content: '';
  width: 8px;
  height: 8px;
  background: #0075ff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3px;
}

form table tr th input[type='radio'] + span,
form table tr td input[type='radio'] + span {
  padding-left: 20px;
  position: relative;
  margin-right: 20px;
}

@media screen and (max-width: 992px) {
  form table tr th input[type='radio'] + span,
  form table tr td input[type='radio'] + span {
    margin-right: 10px;
  }
}

form table tr th input[type='radio'] + span::before,
form table tr td input[type='radio'] + span::before {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

form table tr th input[type='radio']:checked + label,
form table tr td input[type='radio']:checked + label {
  background: unset;
}

form table tr th textarea,
form table tr td textarea {
  width: 94%;
  height: 142px;
}

@media screen and (max-width: 768px) {
  form table tr th textarea,
  form table tr td textarea {
    width: 100%;
  }
}

form table tr th {
  text-align: left;
  vertical-align: middle;
  width: 42%;
  padding: 10px 10px;
  background-color: rgba(0, 169, 172, 0.1);
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  form table tr th {
    width: auto;
    display: block;
    padding: 5px;
  }
}

form table tr th .hissu {
  display: inline-block;
  color: red;
  font-size: 1.2rem;
}

form table tr td {
  text-align: left;
  padding: 8px 15px;
  background-color: #fff;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  form table tr td {
    padding: 5px;
  }
}

form table tr td fieldset.column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

form table tr td fieldset.column span {
  margin-left: 0 !important;
}

#formnote {
  margin-bottom: 50px;
}

@media screen and (max-width: 992px) {
  #formnote {
    margin-bottom: 35px;
  }
}

.formErrorContent {
  color: #ee0101 !important;
  background: #fff !important;
  border: 1px solid #ee0101 !important;
  box-shadow: 0 0 4px #000 !important;
}

.formErrorArrow {
  margin: -1px 0 0 13px !important;
}

.formError .line1, .formError .line2 {
  background: none !important;
}

.formError .line1, .formError .line2, .formError .line3, .formError .line4, .formError .line5, .formError .line6, .formError .line7, .formError .line8, .formError .line9, .formError .line10 {
  border: none !important;
  box-shadow: none !important;
}

/* *****************************
 * List
 **************************** */
.c-list1 {
  padding: 0 100px;
}

@media screen and (max-width: 992px) {
  .c-list1 {
    padding: 0 50px;
  }
}

@media screen and (max-width: 768px) {
  .c-list1 {
    padding: 0;
  }
}

.c-list1 ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -17px;
}

@media screen and (max-width: 576px) {
  .c-list1 ul {
    margin: 0;
  }
}

.c-list1 ul li {
  width: 100%;
  max-width: calc(100% / 2 - 34px);
  margin: 0 17px 25px;
  background: #a8d7e2;
  border: 3px solid #aaaaaa;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: #fff;
  font-weight: 600;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  text-align: center;
  font-size: 2.5rem;
  line-height: 40px;
}

@media screen and (max-width: 992px) {
  .c-list1 ul li {
    font-size: 1.8rem;
    line-height: 32px;
    padding: 15px;
  }
}

@media screen and (max-width: 768px) {
  .c-list1 ul li {
    line-height: 26px;
  }
}

@media screen and (max-width: 576px) {
  .c-list1 ul li {
    max-width: 100%;
    height: 100px;
    margin: 0 0 25px;
  }
}

.c-list2 {
  margin-bottom: 100px;
}

@media screen and (max-width: 992px) {
  .c-list2 {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .c-list2 {
    margin-bottom: 35px;
  }
}

.c-list2 ul,
.c-list2 ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px;
}

@media screen and (max-width: 576px) {
  .c-list2 ul,
  .c-list2 ol {
    margin: 0;
  }
}

.c-list2 ul li,
.c-list2 ol li {
  width: 100%;
  max-width: calc(100% / 2 - 50px);
  margin: 0 25px 35px;
}

@media screen and (max-width: 576px) {
  .c-list2 ul li,
  .c-list2 ol li {
    max-width: 100%;
    margin: 0 0 35px;
  }
}

.c-list2__content p {
  letter-spacing: 0.05em;
}

.c-list2__content p > span {
  font-size: 2.9rem;
  letter-spacing: 0.05em;
  line-height: 37px;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  display: block;
  margin-bottom: 28px;
}

@media screen and (max-width: 992px) {
  .c-list2__content p > span {
    font-size: 2rem;
    line-height: 32px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 768px) {
  .c-list2__content p > span {
    font-size: 1.8rem;
    line-height: 28px;
  }
}

@media screen and (max-width: 992px) {
  .c-list2__content p {
    line-height: 26px;
  }
}

.c-list2__content--top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.c-list2__content--top h3,
.c-list2__content--top h4 {
  font-size: 2.9rem;
  line-height: 37px;
  letter-spacing: 0.05em;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  font-weight: 400;
}

@media screen and (max-width: 992px) {
  .c-list2__content--top h3,
  .c-list2__content--top h4 {
    font-size: 2rem;
    line-height: 32px;
    width: calc(100% - 75px);
  }
}

@media screen and (max-width: 768px) {
  .c-list2__content--top h3,
  .c-list2__content--top h4 {
    font-size: 1.8rem;
    line-height: 28px;
  }
}

.c-list2__content--top span {
  display: block;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 96.85px;
  height: 96.85px;
  border-radius: 50%;
  background: #877858;
  color: #fff;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 2.3rem;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 992px) {
  .c-list2__content--top span {
    width: 60px;
    height: 60px;
    font-size: 1.8rem;
  }
}

.c-list2__img {
  margin-top: 38px;
  text-align: center;
}

.c-list2__img img {
  max-width: 100%;
}

.c-list3 {
  margin-bottom: 110px;
}

@media screen and (max-width: 992px) {
  .c-list3 {
    margin-bottom: 55px;
  }
}

.c-list3 ol li {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.c-list3 ol li:not(:last-child) {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .c-list3 ol li:not(:last-child) {
    margin-bottom: 45px;
  }
}

.c-list3 ol li:nth-child(2n + 2) {
  flex-direction: row-reverse;
}

.c-list3__content {
  position: relative;
  width: 50%;
}

@media screen and (max-width: 1600px) {
  .c-list3__content {
    padding-left: 120px;
  }
}

@media screen and (max-width: 992px) {
  .c-list3__content {
    width: 60%;
    padding-left: 100px;
  }
}

@media screen and (max-width: 768px) {
  .c-list3__content {
    width: 100%;
    margin-bottom: 35px;
  }
}

.c-list3__content > span {
  position: absolute;
  font-family: hobo-std, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3.5rem;
  line-height: 35px;
  color: #fff;
  z-index: 1;
  top: 45px;
  right: calc(100% + 55px);
}

@media screen and (max-width: 1600px) {
  .c-list3__content > span {
    right: unset;
    left: 45px;
  }
}

@media screen and (max-width: 992px) {
  .c-list3__content > span {
    font-size: 2.3rem;
    top: 40px;
  }
}

.c-list3__content > span::before {
  z-index: -1;
  width: 92.3px;
  height: 108.16px;
  background: url(../images/common/svg/icon-point.svg) no-repeat;
  background-size: cover;
  content: '';
  position: absolute;
  top: 5%;
  left: 25%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 992px) {
  .c-list3__content > span::before {
    width: 68px;
    height: 80px;
    top: 10%;
  }
}

.c-list3__content h3 {
  font-size: 2.4rem;
  line-height: 31px;
  letter-spacing: 0.05em;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  margin-bottom: 40px;
  font-weight: 400;
}

@media screen and (max-width: 992px) {
  .c-list3__content h3 {
    font-size: 1.8rem;
    line-height: 28px;
    margin-bottom: 20px;
  }
}

.c-list3__content p {
  letter-spacing: 0.05em;
  width: 97%;
}

@media screen and (max-width: 992px) {
  .c-list3__content p {
    width: 100%;
    line-height: 26px;
  }
}

.c-list3__img {
  width: 34.61%;
}

@media screen and (max-width: 768px) {
  .c-list3__img {
    width: 100%;
  }
}

.c-list3__img img {
  aspect-ratio: 3/2;
  width: 100%;
}

.c-list4 ul {
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .c-list4 ul {
    margin: 0 -7.5px;
    justify-content: unset;
  }
}

.c-list4 ul li {
  width: 100%;
  max-width: calc(100% / 4 - 30px);
  margin: 0 15px 80px;
}

@media screen and (max-width: 992px) {
  .c-list4 ul li {
    max-width: calc(100% / 3 - 30px);
  }
}

@media screen and (max-width: 768px) {
  .c-list4 ul li {
    max-width: calc(100% / 2 - 15px);
    margin: 0 7.5px 30px;
  }
}

@media screen and (max-width: 480px) {
  .c-list4 ul li {
    margin-bottom: 20px;
  }
}

.c-list4 ul li figure img {
  width: 100%;
  margin-bottom: 20px;
}

@media screen and (max-width: 480px) {
  .c-list4 ul li figure img {
    margin-bottom: 10px;
  }
}

.c-list4 ul li figure figcaption {
  font-family: Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 27px;
  color: #707070;
}

@media screen and (max-width: 992px) {
  .c-list4 ul li figure figcaption {
    font-size: 1.6rem;
    line-height: 26px;
  }
}

.c-list5 {
  padding: 0 20px;
  margin-bottom: 45px;
}

@media screen and (max-width: 992px) {
  .c-list5 {
    padding: 0 15px;
  }
}

@media screen and (max-width: 768px) {
  .c-list5 {
    padding: 0;
  }
}

.c-list5.mb25 {
  margin-bottom: 25px;
}

.c-list5 ul {
  margin: 0 -50px;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1200px) {
  .c-list5 ul {
    margin: 0 -25px;
  }
}

@media screen and (max-width: 768px) {
  .c-list5 ul {
    margin: 0 -7.5px;
  }
}

@media screen and (max-width: 576px) {
  .c-list5 ul {
    margin: 0;
  }
}

.c-list5 ul li {
  width: 100%;
  max-width: calc(100% / 3 - 100px);
  margin: 0 50px;
  border: 3px solid #aaaaaa;
  text-align: center;
  height: 200px;
  background: #a8d7e2;
  color: #fff;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .c-list5 ul li {
    max-width: calc(100% / 3 - 50px);
    margin: 0 25px;
  }
}

@media screen and (max-width: 992px) {
  .c-list5 ul li {
    border-width: 2px;
    height: 120px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .c-list5 ul li {
    max-width: calc(100% / 2 - 15px);
    margin: 0 7.5px 15px;
    border-width: 1px;
    height: 90px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 576px) {
  .c-list5 ul li {
    margin: 0 0 15px;
    max-width: 100%;
  }
}

.c-list6 {
  margin-bottom: 100px;
  width: 100%;
  padding-right: 160px;
  position: relative;
}

@media screen and (max-width: 992px) {
  .c-list6 {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .c-list6 {
    padding-right: 0;
  }
}

.c-list6::before {
  content: '';
  width: 170px;
  height: 170px;
  position: absolute;
  right: 0;
  top: 5%;
  background: url(../images/common/icon-waterseal.png) no-repeat;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .c-list6::before {
    width: 120px;
    height: 120px;
    bottom: 80%;
    top: unset;
  }
}

.c-list6 ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;
}

@media screen and (max-width: 768px) {
  .c-list6 ul {
    margin: 0;
  }
}

.c-list6 ul li {
  width: 100%;
  max-width: calc(100% / 3 - 28px);
  margin: 0 14px 20px;
  border-bottom: 1px dashed #4fa9be;
}

@media screen and (max-width: 1200px) {
  .c-list6 ul li {
    max-width: calc(100% / 2 - 28px);
  }
}

@media screen and (max-width: 768px) {
  .c-list6 ul li {
    max-width: 100%;
    margin: 0 0 20px;
  }
}

.c-list6 ul li a {
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  color: #393327;
  font-size: 2rem;
  line-height: 41px;
  padding-left: 38px;
  position: relative;
  display: block;
}

@media screen and (max-width: 992px) {
  .c-list6 ul li a {
    font-size: 1.6rem;
    line-height: 32px;
  }
}

.c-list6 ul li a::before {
  content: '';
  width: 28px;
  height: 29px;
  background: url(../images/common/svg/icon-double-circle.svg) no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.c-list__check {
  display: flex;
  flex-wrap: wrap;
}

.c-list__check li {
  width: 50%;
  font-size: 1.8rem;
  line-height: 23px;
  margin-bottom: 20px;
  color: #707070;
  padding-left: 36px;
  position: relative;
}

@media screen and (max-width: 1200px) {
  .c-list__check li {
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .c-list__check li {
    font-size: 1.6rem;
    padding-left: 25px;
  }
}

.c-list__check li::before {
  content: '';
  width: 20px;
  height: 20px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 3px solid #4fa9be;
}

@media screen and (max-width: 992px) {
  .c-list__check li::before {
    width: 15px;
    height: 15px;
  }
}

.c-list__triple ul {
  margin: 0 -25px;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 992px) {
  .c-list__triple ul {
    margin: 0 -7.5px;
  }
}

.c-list__triple ul li {
  width: 100%;
  max-width: calc(100% / 3 - 50px);
  margin: 0 25px 25px;
}

@media screen and (max-width: 992px) {
  .c-list__triple ul li {
    margin: 0 7.5px 15px;
    max-width: calc(100% / 3 - 15px);
  }
}

@media screen and (max-width: 768px) {
  .c-list__triple ul li {
    max-width: calc(100% / 2 - 15px);
  }
}

.c-list__triple ul li figure {
  width: 100%;
}

.c-list__triple ul li figure img {
  width: 100%;
}

.c-list__triple ul li figure figcaption {
  font-size: 2rem;
  line-height: 26px;
  color: #707070;
  padding: 14px 0;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .c-list__triple ul li figure figcaption {
    padding: 5px 0;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .c-list__triple ul li figure figcaption {
    font-size: 1.6rem;
  }
}

.c-list__feature {
  padding: 0 30px;
  margin-bottom: 80px;
}

@media screen and (max-width: 992px) {
  .c-list__feature {
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 768px) {
  .c-list__feature {
    padding: 0;
  }
}

.c-list__feature ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}

@media screen and (max-width: 992px) {
  .c-list__feature ul {
    margin: 0 -7.5px;
  }
}

@media screen and (max-width: 576px) {
  .c-list__feature ul {
    margin: 0;
  }
}

.c-list__feature ul li {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #707070;
  width: 100%;
  max-width: calc(100% / 3 - 40px);
  margin: 0 20px 30px;
}

@media screen and (max-width: 992px) {
  .c-list__feature ul li {
    margin: 0 7.5px 15px;
    max-width: calc(100% / 2 - 15px);
  }
}

@media screen and (max-width: 576px) {
  .c-list__feature ul li {
    max-width: 100%;
    margin: 0 0 20px;
  }
}

.c-list__feature ul li h4 {
  font-size: 2rem;
  line-height: 26px;
  letter-spacing: 0.05em;
  color: #4fa9be;
  margin-bottom: 20px;
}

@media screen and (max-width: 992px) {
  .c-list__feature ul li h4 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
}

.c-list__feature ul li p {
  letter-spacing: 0.05em;
}

@media screen and (max-width: 768px) {
  .c-list__feature ul li p {
    line-height: 26px;
  }
}

.c-list__feature--inner {
  padding: 15px 20px 10px;
}

@media screen and (max-width: 992px) {
  .c-list__feature--inner {
    padding: 15px;
  }
}

.c-list__point {
  position: relative;
  z-index: 1;
  margin-bottom: 140px;
}

@media screen and (max-width: 1200px) {
  .c-list__point {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 992px) {
  .c-list__point {
    margin-bottom: 65px;
  }
}

@media screen and (max-width: 768px) {
  .c-list__point {
    margin-bottom: 35px;
  }
}

.c-list__point--decor {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30.75%;
}

@media screen and (max-width: 576px) {
  .c-list__point--decor {
    width: calc(100% - 50px);
    margin: 0 auto;
    position: unset;
  }
}

.c-list__point--decor img {
  width: 100%;
}

.c-list__point ol li {
  padding-left: 140px;
  position: relative;
}

.c-list__point ol li:not(:last-child) {
  margin-bottom: 100px;
}

@media screen and (max-width: 992px) {
  .c-list__point ol li:not(:last-child) {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 768px) {
  .c-list__point ol li:not(:last-child) {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 992px) {
  .c-list__point ol li {
    padding-left: 100px;
  }
}

@media screen and (max-width: 768px) {
  .c-list__point ol li {
    padding-left: 0;
  }
}

.c-list__point ol li h4 {
  font-size: 2.4rem;
  line-height: 39px;
  letter-spacing: 0.05em;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  margin-bottom: 40px;
}

@media screen and (max-width: 992px) {
  .c-list__point ol li h4 {
    font-size: 1.8rem;
    line-height: 32px;
    margin-bottom: 20px;
  }
}

.c-list__point ol li > span {
  position: absolute;
  font-family: hobo-std, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3.5rem;
  line-height: 35px;
  color: #fff;
  z-index: 1;
  top: 45px;
  right: calc(100% + 55px);
}

@media screen and (max-width: 1600px) {
  .c-list__point ol li > span {
    right: unset;
    left: 45px;
  }
}

@media screen and (max-width: 992px) {
  .c-list__point ol li > span {
    font-size: 2.3rem;
    top: 40px;
  }
}

@media screen and (max-width: 768px) {
  .c-list__point ol li > span {
    position: unset;
    display: block;
    margin-bottom: 15px;
    padding-left: 25px;
  }
}

.c-list__point ol li > span::before {
  z-index: -1;
  width: 92.3px;
  height: 108.16px;
  background: url(../images/common/svg/icon-point.svg) no-repeat;
  background-size: cover;
  content: '';
  position: absolute;
  top: 5%;
  left: 25%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 992px) {
  .c-list__point ol li > span::before {
    width: 68px;
    height: 80px;
    top: 10%;
  }
}

@media screen and (max-width: 768px) {
  .c-list__point ol li > span::before {
    top: 5px;
    left: 30px;
  }
}

.c-list__note {
  max-width: 1450px;
  margin-bottom: 170px;
}

@media screen and (max-width: 1200px) {
  .c-list__note {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 992px) {
  .c-list__note {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 768px) {
  .c-list__note {
    margin-bottom: 50px;
  }
}

.c-list__note ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7.5px;
}

@media screen and (max-width: 576px) {
  .c-list__note ol {
    margin: 0;
  }
}

.c-list__note ol li {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: calc(100% / 3 - 15px);
  margin: 0 7.5px;
}

@media screen and (max-width: 1200px) {
  .c-list__note ol li {
    z-index: -1;
  }
}

@media screen and (max-width: 992px) {
  .c-list__note ol li {
    max-width: calc(100% / 2 - 15px);
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 576px) {
  .c-list__note ol li {
    max-width: 100%;
    margin: 0 0 35px;
  }
}

.c-list__note ol li > span {
  position: absolute;
  font-family: hobo-std, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3.5rem;
  line-height: 35px;
  color: #fff;
  z-index: -1;
  top: 3.5%;
  left: 9%;
}

@media screen and (max-width: 1600px) {
  .c-list__note ol li > span {
    left: 8%;
    top: 3%;
  }
}

@media screen and (max-width: 1200px) {
  .c-list__note ol li > span {
    z-index: 1;
    top: 5%;
  }
}

@media screen and (max-width: 992px) {
  .c-list__note ol li > span {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 576px) {
  .c-list__note ol li > span {
    top: 7%;
    left: 11%;
  }
}

.c-list__note ol li > span::before {
  z-index: -1;
  width: 92.3px;
  height: 108.16px;
  background: url(../images/common/svg/icon-point.svg) no-repeat;
  background-size: cover;
  content: '';
  position: absolute;
  top: 5%;
  left: 25%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 992px) {
  .c-list__note ol li > span::before {
    width: 68px;
    height: 80px;
    top: 10%;
  }
}

.c-list__note ol li figure {
  width: 100%;
}

.c-list__note ol li figure img {
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 50px;
  border: 1px solid #ded9d9;
}

@media screen and (max-width: 992px) {
  .c-list__note ol li figure img {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 768px) {
  .c-list__note ol li figure img {
    margin-bottom: 20px;
  }
}

.c-list__note ol li figure figcaption {
  font-size: 3rem;
  line-height: 39px;
  text-align: center;
  font-weight: 600;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
}

@media screen and (max-width: 992px) {
  .c-list__note ol li figure figcaption {
    font-size: 2.4rem;
    line-height: 32px;
  }
}

@media screen and (max-width: 768px) {
  .c-list__note ol li figure figcaption {
    font-size: 1.8rem;
    line-height: 28px;
  }
}

.c-list__news {
  margin-bottom: 87.5px;
}

@media screen and (max-width: 992px) {
  .c-list__news {
    margin-bottom: 45px;
  }
}

.c-list__news--top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 992px) {
  .c-list__news--top {
    margin-bottom: 10px;
  }
}

.c-list__news ul li {
  margin-bottom: 20px;
}

.c-list__news ul li a {
  display: block;
}

.c-list__news ul li a article {
  padding-bottom: 18.5px;
  border-bottom: 1px solid #929292;
}

@media screen and (max-width: 992px) {
  .c-list__news ul li a article {
    padding-bottom: 10px;
  }
}

.c-list__news ul li a article time {
  color: #707070;
  font-size: 2rem;
  font-weight: 600;
  margin-right: 30px;
}

@media screen and (max-width: 992px) {
  .c-list__news ul li a article time {
    font-size: 1.6rem;
    margin-right: 15px;
  }
}

@media screen and (max-width: 768px) {
  .c-list__news ul li a article time {
    font-size: 1.4rem;
    margin-right: 10px;
  }
}

.c-list__news ul li a article span {
  font-size: 1.6rem;
  line-height: 21px;
  font-weight: 600;
  color: #fff;
  padding: 10px 15px;
  max-width: 95px;
  width: 100%;
  text-align: center;
  display: block;
}

@media screen and (max-width: 992px) {
  .c-list__news ul li a article span {
    padding: 5px 15px;
  }
}

@media screen and (max-width: 768px) {
  .c-list__news ul li a article span {
    font-size: 1.4rem;
  }
}

.c-list__news ul li a article span.news {
  background: #4b90cc;
}

.c-list__news ul li a article span.blog {
  background: #4ac9e9;
}

.c-list__news ul li a article h2 {
  font-size: 1.8rem;
  color: #393327;
  text-align: left;
  padding: 0;
  width: unset;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .c-list__news ul li a article h2 {
    font-size: 1.6rem;
    line-height: 28px;
  }
}

/* *****************************
 * Main visual
 **************************** */
.p-mv {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .p-mv {
    margin-top: 160px;
  }
}

.p-mv__bg {
  position: relative;
  z-index: -1;
}

.p-mv__bg::before {
  content: '';
  width: 100%;
  height: 100%;
  background: #6a6a6a;
  opacity: 0.53;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.p-mv__bg img {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .p-mv__bg img {
    height: 50vw;
    object-fit: cover;
  }
}

@media screen and (max-width: 768px) {
  .p-mv__bg img {
    height: 70vw;
  }
}

.p-mv h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .p-mv h1 {
    top: 40%;
  }
}

.p-mv h1 strong {
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  color: #fff;
  font-size: 6rem;
  letter-spacing: 0.05em;
  line-height: 44px;
  display: block;
  text-align: center;
  margin-bottom: 15px;
}

@media screen and (max-width: 1200px) {
  .p-mv h1 strong {
    font-size: 4.2rem;
    line-height: 38px;
  }
}

@media screen and (max-width: 992px) {
  .p-mv h1 strong {
    font-size: 3rem;
    line-height: 30px;
    margin-bottom: 5px;
  }
}

.p-mv h1 span {
  font-family: '游ゴシック', '游ゴシック体', 'Yu Gothic Medium', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  color: #fff;
  font-size: 2rem;
  letter-spacing: 0.05em;
  line-height: 32px;
  font-weight: 500;
  display: block;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .p-mv h1 span {
    font-size: 1.8rem;
    line-height: 28px;
  }
}

/* *****************************
 * Other
 **************************** */
.c-block1 {
  margin-bottom: 120px;
}

@media screen and (max-width: 992px) {
  .c-block1 {
    margin-bottom: 60px;
  }
}

.c-block1__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.c-block1__inner--left {
  width: calc(50% - 22px);
}

@media screen and (max-width: 768px) {
  .c-block1__inner--left {
    width: 100%;
    margin-bottom: 35px;
  }
}

.c-block1__inner--left h2 {
  font-size: 3rem;
  letter-spacing: 0.05em;
  line-height: 39px;
  margin-bottom: 39px;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  text-align: left;
  color: #393327;
  padding: 0;
}

.c-block1__inner--left h3 {
  font-size: 2rem;
  line-height: 26px;
  color: #f90415;
  text-align: center;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
}

.c-block1__inner--left > figure img {
  width: 100%;
}

.c-block1__inner--right {
  width: 50%;
  line-height: 0;
}

@media screen and (max-width: 768px) {
  .c-block1__inner--right {
    width: 100%;
  }
}

.c-block1__inner--right iframe {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .c-block1__inner--right iframe {
    height: 50vw;
  }
}

.c-block1__inner--note {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

@media screen and (max-width: 992px) {
  .c-block1__inner--note {
    margin-bottom: 35px;
  }
}

.c-block1__inner--note span,
.c-block1__inner--note p {
  font-size: 1.7rem;
  line-height: 34px;
}

@media screen and (max-width: 992px) {
  .c-block1__inner--note span,
  .c-block1__inner--note p {
    font-size: 1.6rem;
    line-height: 26px;
  }
}

.c-block1__inner--note span {
  display: block;
  margin-right: 30px;
}

@media screen and (max-width: 992px) {
  .c-block1__inner--note p {
    width: calc(100% - 78px);
  }
}

.c-block2__item:not(:last-child) {
  margin-bottom: 60px;
}

@media screen and (max-width: 992px) {
  .c-block2__item:not(:last-child) {
    margin-bottom: 40px;
  }
}

.c-block2__item--top {
  position: relative;
  margin-bottom: 50px;
}

@media screen and (max-width: 992px) {
  .c-block2__item--top {
    margin-bottom: 35px;
  }
}

.c-block2__item--top h4 {
  line-height: 66px;
  background: #f8eaa5;
  border-radius: 5px;
  padding: 0 10px;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  color: #707070;
  font-size: 3rem;
  letter-spacing: 0.05em;
  font-weight: 400;
}

@media screen and (max-width: 1200px) {
  .c-block2__item--top h4 {
    font-size: 2.4rem;
    line-height: 55px;
  }
}

@media screen and (max-width: 992px) {
  .c-block2__item--top h4 {
    font-size: 1.8rem;
    line-height: 26px;
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .c-block2__item--top h4 {
    padding: 10px 100px 10px 10px;
  }
}

.c-block2__item--top > span {
  position: absolute;
  font-family: hobo-std, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3.5rem;
  line-height: 35px;
  color: #fff;
  z-index: 1;
  bottom: 25px;
  right: 50px;
}

@media screen and (max-width: 992px) {
  .c-block2__item--top > span {
    font-size: 2.3rem;
    right: 3%;
    bottom: 30%;
  }
}

@media screen and (max-width: 768px) {
  .c-block2__item--top > span {
    right: 25px;
  }
}

.c-block2__item--top > span::before {
  z-index: -1;
  width: 92.3px;
  height: 108.16px;
  background: url(../images/common/svg/icon-point.svg) no-repeat;
  background-size: cover;
  content: '';
  position: absolute;
  top: 5%;
  left: 25%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 992px) {
  .c-block2__item--top > span::before {
    width: 68px;
    height: 80px;
    top: 10%;
  }
}

.c-block2__item--bot {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-block2__item--bot p {
  width: 58%;
  font-size: 2rem;
  line-height: 40px;
  color: #707070;
}

@media screen and (max-width: 1200px) {
  .c-block2__item--bot p {
    width: 55%;
  }
}

@media screen and (max-width: 992px) {
  .c-block2__item--bot p {
    font-size: 1.6rem;
    line-height: 32px;
  }
}

@media screen and (max-width: 768px) {
  .c-block2__item--bot p {
    width: 100%;
    line-height: 28px;
    margin-bottom: 25px;
  }
}

.c-block2__item--bot figure {
  width: 42%;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .c-block2__item--bot figure {
    width: 100%;
  }
}

.c-block2__item--bot figure img {
  width: 100%;
  max-width: 509px;
}

@media screen and (max-width: 768px) {
  .c-block2__item--bot figure img {
    max-width: 100%;
  }
}

.c-block2__item--bot figure figcaption {
  font-size: 1.4rem;
  color: #707070;
  line-height: 22px;
  text-align: right;
  margin-top: 15px;
}

.c-block2__item--row {
  display: flex;
  flex-wrap: wrap;
}

.c-block2__item--box {
  width: 50%;
  padding: 25px;
}

@media screen and (max-width: 992px) {
  .c-block2__item--box {
    width: 100%;
    padding: 25px 0;
  }
}

.c-block2__item--box figure {
  text-align: center;
  margin-bottom: 28px;
}

.c-block2__item--box figure figcaption {
  font-size: 2rem;
  line-height: 26px;
  margin-bottom: 25px;
  font-weight: 600;
  color: #707070;
}

@media screen and (max-width: 992px) {
  .c-block2__item--box figure figcaption {
    font-size: 1.8rem;
  }
}

.c-block2__item--box p {
  font-size: 2rem;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 40px;
}

@media screen and (max-width: 992px) {
  .c-block2__item--box p {
    font-size: 1.8rem;
  }
}

.c-block2__item--box dl dt {
  display: block;
  width: 96.85px;
  height: 96.85px;
  background: #658185;
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  margin-right: 30px;
}

@media screen and (max-width: 992px) {
  .c-block2__item--box dl dt {
    width: 80px;
    height: 80px;
    font-size: 1.4rem;
    margin-right: 20px;
  }
}

.c-block2__item--box dl dt.pink-bg {
  background: #fb8da0;
}

.c-block2__item--box dl dd {
  width: calc(100% - 127px);
  color: #878585;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 1.8rem;
  line-height: 32px;
}

@media screen and (max-width: 992px) {
  .c-block2__item--box dl dd {
    width: calc(100% - 100px);
  }
}

.c-block2__item--box dl .c-block2__item--row:not(:last-child) {
  margin-bottom: 45px;
}

@media screen and (max-width: 992px) {
  .c-block2__item--box dl .c-block2__item--row:not(:last-child) {
    margin-bottom: 25px;
  }
}

.c-block2__item--inner {
  background: #f6f6f6;
  padding: 30px 30px 40px;
}

@media screen and (max-width: 992px) {
  .c-block2__item--inner {
    padding: 15px;
  }
}

.c-block3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 150px;
}

@media screen and (max-width: 1200px) {
  .c-block3 {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 992px) {
  .c-block3 {
    margin-bottom: 65px;
  }
}

@media screen and (max-width: 768px) {
  .c-block3 {
    margin-bottom: 50px;
  }
}

.c-block3__content {
  width: calc(59.08% - 30px);
}

@media screen and (max-width: 768px) {
  .c-block3__content {
    width: 100%;
    margin-bottom: 35px;
  }
}

.c-block3__content .c-txt__2rem {
  margin-bottom: 64px;
}

@media screen and (max-width: 992px) {
  .c-block3__content .c-txt__2rem {
    margin-bottom: 35px;
  }
}

.c-block3__content--row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 650px;
}

@media screen and (max-width: 768px) {
  .c-block3__content--row {
    max-width: 100%;
  }
}

.c-block3__content--row figure {
  width: 100%;
  max-width: calc(100% / 2 - 15px);
}

@media screen and (max-width: 768px) {
  .c-block3__content--row figure {
    max-width: calc(100% / 2 - 7.5px);
  }
}

.c-block3__content--row figure img {
  width: 100%;
}

.c-block3__img {
  width: 40.92%;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .c-block3__img {
    width: 100%;
  }
}

.c-block3__img img {
  max-width: 100%;
}

.c-block4 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 70px;
}

.c-block4.mb160 {
  margin-bottom: 160px;
}

@media screen and (max-width: 992px) {
  .c-block4.mb160 {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 768px) {
  .c-block4.mb160 {
    margin-bottom: 45px;
  }
}

.c-block4__img {
  width: 41.07%;
}

@media screen and (max-width: 768px) {
  .c-block4__img {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .c-block4__img img {
    width: 100%;
  }
}

.c-block4__content {
  width: calc(58.03% - 30px);
}

@media screen and (max-width: 768px) {
  .c-block4__content {
    width: 100%;
    margin-bottom: 35px;
  }
}

.c-procedure {
  margin-bottom: 70px;
}

@media screen and (max-width: 992px) {
  .c-procedure {
    margin-bottom: 35px;
  }
}

.c-procedure ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

@media screen and (max-width: 576px) {
  .c-procedure ul {
    margin: 0 -7.5px;
  }
}

.c-procedure ul li {
  width: 100%;
  max-width: calc(100% / 6 - 20px);
  margin: 0 10px;
  position: relative;
  background: #4fa9be;
  padding: 25px 10px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: block;
  font-size: 2rem;
  letter-spacing: 0.05em;
  text-align: center;
  line-height: 26px;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  color: #fff;
}

@media screen and (max-width: 1200px) {
  .c-procedure ul li {
    max-width: calc(100% / 4 - 20px);
    margin-bottom: 20px;
    height: 130px;
  }
}

@media screen and (max-width: 992px) {
  .c-procedure ul li {
    max-width: calc(100% / 3 - 20px);
    margin-bottom: 20px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 576px) {
  .c-procedure ul li {
    max-width: calc(100% / 2 - 15px);
    margin: 0 7.5px 15px;
    height: 110px;
    padding: 10px;
  }
}

.c-procedure ul li.is-active {
  padding: 15px;
  border: 8px solid #e9cd41;
}

@media screen and (max-width: 992px) {
  .c-procedure ul li.is-active {
    border-width: 4px;
  }
}

@media screen and (max-width: 992px) {
  .c-procedure ul li:nth-child(3)::before {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .c-procedure ul li:nth-child(3)::before {
    display: block;
  }
}

@media screen and (max-width: 1200px) {
  .c-procedure ul li:nth-child(4)::before {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .c-procedure ul li:nth-child(4)::before {
    display: block;
  }
}

@media screen and (max-width: 576px) {
  .c-procedure ul li:nth-child(2n + 2)::before {
    display: none;
  }
}

.c-procedure ul li:not(:last-child).is-active {
  padding: 15px;
  border: 8px solid #e9cd41;
}

@media screen and (max-width: 992px) {
  .c-procedure ul li:not(:last-child).is-active {
    border-width: 4px;
  }
}

.c-procedure ul li:not(:last-child).is-active::before {
  left: calc(100% + 8px);
}

@media screen and (max-width: 992px) {
  .c-procedure ul li:not(:last-child).is-active::before {
    left: calc(100% + 4px);
  }
}

.c-procedure ul li:not(:last-child)::before {
  width: 20px;
  height: 30px;
  content: '';
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  background: url(../images/common/svg/angle-right.svg) no-repeat;
  background-size: cover;
}

@media screen and (max-width: 576px) {
  .c-procedure ul li:not(:last-child)::before {
    width: 15px;
    height: 22px;
  }
}

.c-para1 section > figure {
  text-align: center;
}

.c-para1 section > figure:not(:last-child) {
  margin-bottom: 40px;
}

.c-para1 section > figure.mb70 {
  margin-bottom: 70px;
}

@media screen and (max-width: 992px) {
  .c-para1 section > figure.mb70 {
    margin-bottom: 35px;
  }
}

.c-para1 section > figure img {
  max-width: 100%;
}

.c-para1 section > p {
  margin-bottom: 50px;
}

@media screen and (max-width: 992px) {
  .c-para1 section > p {
    margin-bottom: 35px;
  }
}

.c-para1__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 200px;
}

@media screen and (max-width: 1200px) {
  .c-para1__row {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 992px) {
  .c-para1__row {
    margin-bottom: 80px;
  }
}

.c-para1__row.mb80 {
  margin-bottom: 80px;
}

@media screen and (max-width: 992px) {
  .c-para1__row.mb80 {
    margin-bottom: 45px;
  }
}

.c-para1__row dd {
  font-size: 2.4rem;
  line-height: 38px;
  color: #393327;
  letter-spacing: 0.05em;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .c-para1__row dd {
    font-size: 1.8rem;
    line-height: 30px;
  }
}

@media screen and (max-width: 768px) {
  .c-para1__row dd {
    font-size: 1.6rem;
    line-height: 28px;
  }
}

.c-para1__row dd:not(:last-child) {
  margin-bottom: 40px;
}

@media screen and (max-width: 992px) {
  .c-para1__row dd:not(:last-child) {
    margin-bottom: 25px;
  }
}

.c-para1__row--text {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .c-para1__row--text {
    width: 100%;
    margin-bottom: 25px;
  }
}

.c-para1__row--img {
  width: calc(50% - 10px);
}

@media screen and (max-width: 768px) {
  .c-para1__row--img {
    width: 100%;
  }
}

.c-para1__row--img.minus20px {
  width: calc(50% - 20px);
}

@media screen and (max-width: 768px) {
  .c-para1__row--img.minus20px {
    width: 100%;
  }
}

.c-para1__row--img img {
  width: 100%;
}

.c-para1__row2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .c-para1__row2 {
    margin-bottom: 30px;
  }
}

.c-para1__row2.more-width-img .c-para1__row2--text {
  max-width: 56.61%;
}

@media screen and (max-width: 768px) {
  .c-para1__row2.more-width-img .c-para1__row2--text {
    max-width: 100%;
    margin-bottom: 35px;
  }
}

.c-para1__row2.more-width-img .c-para1__row2--img {
  max-width: 38.46%;
}

@media screen and (max-width: 768px) {
  .c-para1__row2.more-width-img .c-para1__row2--img {
    max-width: 100%;
  }
}

.c-para1__row2 p {
  font-size: 1.6rem;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #393327;
}

.c-para1__row2 p.c-txt__2rem {
  font-size: 2rem;
  line-height: 40px;
  color: #707070;
}

@media screen and (max-width: 992px) {
  .c-para1__row2 p.c-txt__2rem {
    font-size: 1.6rem;
    line-height: 26px;
  }
}

.c-para1__row2 p.c-txt__2rem.mb0 {
  margin-bottom: 0;
}

.c-para1__row2 p.c-txt__2rem.mb20 {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .c-para1__row2 p {
    line-height: 26px;
  }
}

.c-para1__row2 p:not(:last-child) {
  margin-bottom: 100px;
}

@media screen and (max-width: 992px) {
  .c-para1__row2 p:not(:last-child) {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .c-para1__row2 p:not(:last-child) {
    margin-bottom: 35px;
  }
}

.c-para1__row2 p.fs2rem {
  font-size: 2rem;
  line-height: 40px;
  letter-spacing: 0;
}

@media screen and (max-width: 992px) {
  .c-para1__row2 p.fs2rem {
    font-size: 1.8rem;
    line-height: 28px;
  }
}

@media screen and (max-width: 768px) {
  .c-para1__row2 p.fs2rem {
    font-size: 1.6rem;
    line-height: 26px;
  }
}

.c-para1__row2--text {
  width: 100%;
  max-width: calc(62.3% - 30px);
}

@media screen and (max-width: 768px) {
  .c-para1__row2--text {
    max-width: 100%;
    margin-bottom: 35px;
  }
}

.c-para1__row2--img {
  width: 100%;
  max-width: 37.7%;
}

@media screen and (max-width: 768px) {
  .c-para1__row2--img {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .c-para1__row2--img img {
    width: 100%;
  }
}

.c-para1__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 200px;
}

@media screen and (max-width: 1200px) {
  .c-para1__block {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 992px) {
  .c-para1__block {
    margin-bottom: 80px;
  }
}

.c-para1__block--item {
  width: 49%;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .c-para1__block--item:not(:last-child) {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 768px) {
  .c-para1__block--item {
    width: 100%;
    margin-bottom: 0;
  }
}

.c-para1__block--item p {
  font-size: 1.6rem;
  line-height: 32px;
  letter-spacing: 0.05em;
  font-family: '游ゴシック', '游ゴシック体', 'Yu Gothic Medium', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}

.c-para1__block--img {
  text-align: center;
  padding: 45px 0;
}

.c-para1__block--center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
}

@media screen and (max-width: 992px) {
  .c-para1__block--center {
    margin-bottom: 35px;
  }
}

.c-para1__block--circle {
  width: 96.85px;
  height: 96.85px;
  background: #658185;
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  margin: 0 15px 0 3vw;
}

@media screen and (max-width: 576px) {
  .c-para1__block--circle {
    margin-bottom: 30px;
  }
}

.c-para1__block--list {
  font-size: 1.8rem;
  line-height: 32px;
  color: #878585;
}

@media screen and (max-width: 992px) {
  .c-para1__block--list {
    font-size: 1.6rem;
    line-height: 28px;
  }
}

@media screen and (max-width: 576px) {
  .c-para1__block--list {
    margin-bottom: 50px;
  }
  .c-para1__block--list li {
    margin-bottom: 10px;
  }
}

.c-para1__block2 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

@media screen and (max-width: 992px) {
  .c-para1__block2 {
    margin-bottom: 40px;
  }
}

.c-para1__block2.mb100 {
  margin-bottom: 100px;
}

@media screen and (max-width: 992px) {
  .c-para1__block2.mb100 {
    margin-bottom: 65px;
  }
}

.c-para1__block2.p25 .c-para1__block2--left, .c-para1__block2.p25 .c-para1__block2--right {
  padding: 0 15px;
}

@media screen and (max-width: 768px) {
  .c-para1__block2.p25 .c-para1__block2--left, .c-para1__block2.p25 .c-para1__block2--right {
    padding: 0;
  }
}

.c-para1__block2.reverse {
  flex-direction: row-reverse;
}

.c-para1__block2.reverse.mb92 {
  margin-bottom: 92px;
}

@media screen and (max-width: 992px) {
  .c-para1__block2.reverse.mb92 {
    margin-bottom: 65px;
  }
}

@media screen and (max-width: 768px) {
  .c-para1__block2.reverse.mb92 {
    margin-bottom: 35px;
  }
}

.c-para1__block2.reverse .c-para1__block2--left h4 {
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  .c-para1__block2.reverse .c-para1__block2--left h4 {
    margin-left: 0;
  }
}

.c-para1__block2.reverse .c-para1__block2--left > p {
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .c-para1__block2.reverse .c-para1__block2--left > p {
    margin-left: 0;
  }
}

.c-para1__block2--left, .c-para1__block2--right {
  width: 50%;
}

@media screen and (max-width: 1200px) {
  .c-para1__block2--left .c-btn__skeleton, .c-para1__block2--right .c-btn__skeleton {
    max-width: calc(100% - 50px);
  }
}

@media screen and (max-width: 768px) {
  .c-para1__block2--left:not(:last-child), .c-para1__block2--right:not(:last-child) {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .c-para1__block2--left:not(:last-child), .c-para1__block2--right:not(:last-child) {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 768px) {
  .c-para1__block2--left, .c-para1__block2--right {
    width: 100%;
  }
}

.c-para1__block2--left.p0 figure, .c-para1__block2--right.p0 figure {
  padding: 0;
}

.c-para1__block2--left ul li:not(:last-child), .c-para1__block2--right ul li:not(:last-child) {
  margin-bottom: 95px;
}

@media screen and (max-width: 992px) {
  .c-para1__block2--left ul li:not(:last-child), .c-para1__block2--right ul li:not(:last-child) {
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 768px) {
  .c-para1__block2--left ul li:not(:last-child), .c-para1__block2--right ul li:not(:last-child) {
    margin-bottom: 30px;
  }
}

.c-para1__block2--left ul li h4, .c-para1__block2--right ul li h4 {
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .c-para1__block2--left ul li h4, .c-para1__block2--right ul li h4 {
    margin-bottom: 10px;
  }
}

.c-para1__block2--left ul li h4 strong, .c-para1__block2--right ul li h4 strong {
  font-weight: 600;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 2.4rem;
  letter-spacing: 0.05em;
  line-height: 30px;
  display: block;
}

@media screen and (max-width: 992px) {
  .c-para1__block2--left ul li h4 strong, .c-para1__block2--right ul li h4 strong {
    font-size: 1.8rem;
    line-height: 28px;
  }
}

.c-para1__block2--left ul li h4 span, .c-para1__block2--right ul li h4 span {
  display: block;
  color: #4fa9be;
  font-weight: 500;
  letter-spacing: 0.05em;
  font-size: 2rem;
  margin-top: 5px;
}

@media screen and (max-width: 992px) {
  .c-para1__block2--left ul li h4 span, .c-para1__block2--right ul li h4 span {
    font-size: 1.8rem;
  }
}

.c-para1__block2--left ul li p, .c-para1__block2--right ul li p {
  letter-spacing: 0.05em;
}

@media screen and (max-width: 992px) {
  .c-para1__block2--left ul li p, .c-para1__block2--right ul li p {
    line-height: 26px;
  }
}

.c-para1__block2--left > figure, .c-para1__block2--right > figure {
  text-align: center;
  padding: 0 25px;
}

@media screen and (max-width: 768px) {
  .c-para1__block2--left > figure, .c-para1__block2--right > figure {
    padding: 0;
  }
}

.c-para1__block2--left > figure img, .c-para1__block2--right > figure img {
  max-width: 100%;
}

.c-para1__block2--left > p, .c-para1__block2--right > p {
  width: calc(100% - 20px);
}

@media screen and (max-width: 768px) {
  .c-para1__block2--left > p, .c-para1__block2--right > p {
    width: 100%;
  }
}

.c-para1__block2--left > h4, .c-para1__block2--right > h4 {
  font-size: 3rem;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  line-height: 39px;
  color: #393327;
  margin-bottom: 30px;
}

@media screen and (max-width: 992px) {
  .c-para1__block2--left > h4, .c-para1__block2--right > h4 {
    font-size: 2.4rem;
    line-height: 32px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .c-para1__block2--left > h4, .c-para1__block2--right > h4 {
    font-size: 1.8rem;
    line-height: 28px;
  }
}

.c-para1__block2--left .c-title__solid strong, .c-para1__block2--right .c-title__solid strong {
  font-size: 3rem;
  line-height: 39px;
}

@media screen and (max-width: 992px) {
  .c-para1__block2--left .c-title__solid strong, .c-para1__block2--right .c-title__solid strong {
    font-size: 2rem;
    line-height: 32px;
  }
}

.c-para1__block2 > figure {
  text-align: center;
}

.c-para1__btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.c-para1__textbox {
  padding: 0 75px;
  margin-bottom: 70px;
}

@media screen and (max-width: 992px) {
  .c-para1__textbox {
    padding: 0 30px;
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 768px) {
  .c-para1__textbox {
    padding: 0 15px;
  }
}

@media screen and (max-width: 480px) {
  .c-para1__textbox {
    padding: 0;
  }
}

.c-para1__textbox--border {
  background: #fff;
  border: 1px solid #707070;
  padding: 27px;
}

@media screen and (max-width: 768px) {
  .c-para1__textbox--border {
    padding: 15px;
  }
}

.c-para1__textbox--border h3,
.c-para1__textbox--border p {
  font-size: 2rem;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  line-height: 35px;
  color: #7f9195 !important;
}

.c-para1__textbox--border h3.tac,
.c-para1__textbox--border p.tac {
  text-align: center;
}

.c-para1__textbox--border h3 {
  margin-bottom: 0;
}

.c-para1__textbox.mb0 {
  margin-bottom: 0;
}

.c-para1__textbox h3,
.c-para1__textbox p {
  font-size: 2rem;
  line-height: 40px;
}

@media screen and (max-width: 992px) {
  .c-para1__textbox h3,
  .c-para1__textbox p {
    font-size: 1.6rem;
  }
}

.c-para1__textbox h3 > span,
.c-para1__textbox p > span {
  font-weight: 600;
  color: #b40404;
}

.c-para1__textbox h3.tac,
.c-para1__textbox p.tac {
  text-align: center;
}

.c-para1__textbox h3.mb35,
.c-para1__textbox p.mb35 {
  margin-bottom: 35px;
}

.c-para1__textbox > h3 {
  color: #4fa9be;
  margin-bottom: 35px;
}

@media screen and (max-width: 768px) {
  .c-para1__textbox > h3 {
    margin-bottom: 20px;
  }
}

.c-para1__textbox > h3.mb0 {
  margin-bottom: 0;
}

.c-para1__textbox p {
  color: #707070;
}

.c-para1__textbox p > strong {
  z-index: 1;
  position: relative;
  color: #707070;
}

@media screen and (max-width: 720px) {
  .c-para1__textbox p > strong {
    background: #d7ebf9;
  }
}

.c-para1__textbox p > strong::before {
  content: '';
  width: 100%;
  height: 45px;
  z-index: -1;
  background: #d7ebf9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 720px) {
  .c-para1__textbox p > strong::before {
    display: none;
  }
}

.c-para1__txt {
  font-size: 2rem;
  line-height: 40px;
  color: #707070;
  margin-bottom: 70px;
}

@media screen and (max-width: 992px) {
  .c-para1__txt {
    font-size: 1.8rem;
    line-height: 28px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
  .c-para1__txt {
    font-size: 1.6rem;
    line-height: 26px;
    margin-bottom: 25px;
  }
}

.c-para1__img {
  padding-bottom: 127px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .c-para1__img {
    padding-bottom: 0;
  }
}

.c-para1__img figure {
  width: 58.38%;
  box-shadow: 10px 10px 6px rgba(0, 0, 0, 0.16);
}

@media screen and (max-width: 768px) {
  .c-para1__img figure {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .c-para1__img figure:not(:last-child) {
    margin-bottom: 35px;
  }
}

.c-para1__img figure:nth-child(2) {
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 768px) {
  .c-para1__img figure:nth-child(2) {
    position: unset;
  }
}

.c-para1__img figure img {
  width: 100%;
}

.c-para1 .mb150 {
  margin-bottom: 150px;
}

@media screen and (max-width: 992px) {
  .c-para1 .mb150 {
    margin-bottom: 75px;
  }
}

.c-para2 h3 {
  color: #4fa9be;
  font-size: 2rem;
  line-height: 32px;
  letter-spacing: 0.05em;
  font-weight: 600;
  margin-bottom: 20px;
}

@media screen and (max-width: 992px) {
  .c-para2 h3 {
    font-size: 1.8rem;
    line-height: 28px;
  }
}

.c-para2 p {
  font-size: 1.6rem;
  line-height: 32px;
  font-family: '游ゴシック', '游ゴシック体', 'Yu Gothic Medium', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  margin-bottom: 40px;
  color: #393327;
}

@media screen and (max-width: 992px) {
  .c-para2 p {
    line-height: 26px;
    margin-bottom: 25px;
  }
}

.c-para2 p.mb15 {
  margin-bottom: 15px;
}

.c-para2 p.mb100 {
  margin-bottom: 100px;
}

@media screen and (max-width: 992px) {
  .c-para2 p.mb100 {
    margin-bottom: 50px;
  }
}

.c-para2__img {
  text-align: center;
  margin-bottom: 80px;
}

@media screen and (max-width: 992px) {
  .c-para2__img {
    margin-bottom: 40px;
  }
}

.c-para2__block {
  background: url(../images/design1-1/bg-article2-block.png) no-repeat right;
  height: 402px;
  padding: 10px 0;
}

@media screen and (max-width: 992px) {
  .c-para2__block {
    padding: 25px 15px;
  }
}

@media screen and (max-width: 480px) {
  .c-para2__block {
    height: auto;
    background-size: cover;
  }
}

@media screen and (max-width: 992px) {
  .c-para2__block ul {
    padding: 25px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.5);
  }
}

.c-para2__block ul li {
  font-size: 1.6rem;
  line-height: 32px;
  letter-spacing: 0.05em;
  font-family: '游ゴシック', '游ゴシック体', 'Yu Gothic Medium', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}

@media screen and (max-width: 992px) {
  .c-para2__block ul li {
    line-height: 26px;
  }
}

.c-para3:not(:last-child) {
  margin-bottom: 180px;
}

@media screen and (max-width: 1200px) {
  .c-para3:not(:last-child) {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 992px) {
  .c-para3:not(:last-child) {
    margin-bottom: 65px;
  }
}

.c-para3.mb150 {
  margin-bottom: 150px;
}

@media screen and (max-width: 1200px) {
  .c-para3.mb150 {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 992px) {
  .c-para3.mb150 {
    margin-bottom: 65px;
  }
}

@media screen and (max-width: 768px) {
  .c-para3.mb150 {
    margin-bottom: 35px;
  }
}

.c-para3 > h3 {
  font-family: Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-size: 2.4rem;
  line-height: 36px;
  color: #fff;
  padding: 20px 50px;
  border-radius: 50px;
  background: #878585;
  width: 100%;
  text-align: left;
  font-weight: 400;
  margin-bottom: 40px;
}

@media screen and (max-width: 1200px) {
  .c-para3 > h3 {
    padding: 10px 25px;
    font-size: 2rem;
    line-height: 32px;
  }
}

@media screen and (max-width: 992px) {
  .c-para3 > h3 {
    padding: 5px 15px;
    font-size: 1.8rem;
    margin-bottom: 25px;
  }
}

.c-para3__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-para3__row--left, .c-para3__row--right {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .c-para3__row--left, .c-para3__row--right {
    width: 100%;
  }
}

.c-para3__row--left {
  padding: 0 20px;
}

@media screen and (max-width: 1200px) {
  .c-para3__row--left {
    padding: 0 10px;
  }
}

@media screen and (max-width: 768px) {
  .c-para3__row--left {
    padding: 0;
    margin-bottom: 35px;
  }
}

.c-para3__row--left.pl0 {
  padding-left: 0;
}

.c-para3__row--left.mb120 {
  margin-bottom: 120px;
}

@media screen and (max-width: 992px) {
  .c-para3__row--left.mb120 {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 768px) {
  .c-para3__row--left.mb120 {
    margin-bottom: 35px;
  }
}

.c-para3__row--left h4 {
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  font-weight: 600;
  font-size: 3rem;
  line-height: 38px;
  margin-bottom: 25px;
  color: #878585;
}

@media screen and (max-width: 992px) {
  .c-para3__row--left h4 {
    font-size: 2.4rem;
    line-height: 32px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .c-para3__row--left h4 {
    font-size: 1.8rem;
    line-height: 28px;
  }
}

.c-para3__row--left > p {
  font-size: 1.8rem;
  line-height: 32px;
  margin-bottom: 45px;
  color: #707070;
}

.c-para3__row--left > p.mincho-font {
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
}

.c-para3__row--left > p.fsz16rem {
  font-size: 1.6rem;
}

@media screen and (max-width: 992px) {
  .c-para3__row--left > p {
    font-size: 1.6rem;
    line-height: 26px;
    margin-bottom: 30px;
  }
}

.c-para3__row--left dl > dt {
  display: none !important;
}

.c-para3__row--left dl > dt,
.c-para3__row--left dl dd {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: #878585;
}

.c-para3__row--left dl > dt a,
.c-para3__row--left dl dd a {
  color: #cf0707;
}

.c-para3__row--left dl > dt:not(:last-child),
.c-para3__row--left dl dd:not(:last-child) {
  margin-bottom: 45px;
}

@media screen and (max-width: 992px) {
  .c-para3__row--left dl > dt:not(:last-child),
  .c-para3__row--left dl dd:not(:last-child) {
    margin-bottom: 25px;
  }
}

.c-para3__row--left dl > dt div:first-child,
.c-para3__row--left dl dd div:first-child {
  width: 96.85px;
  height: 96.85px;
  background: #658185;
  border-radius: 50%;
  color: #fff;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  letter-spacing: 0.05em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

@media screen and (max-width: 1200px) {
  .c-para3__row--left dl > dt div:first-child,
  .c-para3__row--left dl dd div:first-child {
    font-size: 1.4rem;
    width: 80px;
    height: 80px;
    margin-right: 20px;
  }
}

.c-para3__row--left dl > dt div:first-child.pink-bg,
.c-para3__row--left dl dd div:first-child.pink-bg {
  background: #fb8da0;
}

.c-para3__row--left dl > dt div.txt,
.c-para3__row--left dl dd div.txt {
  max-width: calc(100% - 130px);
}

@media screen and (max-width: 1200px) {
  .c-para3__row--left dl > dt div.txt,
  .c-para3__row--left dl dd div.txt {
    width: calc(100% - 100px);
    font-size: 1.4rem;
    line-height: 22px;
  }
}

.c-para3__row--right {
  text-align: center;
}

.c-para3__row--right img {
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .c-para3__row--right img {
    width: 100%;
  }
}

.c-para3__row--flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 25px;
}

@media screen and (max-width: 1300px) {
  .c-para3__row--flex {
    padding: 0;
  }
}

.c-para3__row--flex.p0 {
  padding: 0;
}

.c-para3__row--flex:not(:last-child) {
  margin-bottom: 45px;
}

@media screen and (max-width: 768px) {
  .c-para3__row--flex:not(:last-child) {
    margin-bottom: 25px;
  }
}

.c-group__img {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.c-group__img figure {
  width: 100%;
}

@media screen and (max-width: 576px) {
  .c-group__img figure:not(:last-child) {
    margin-bottom: 30px;
  }
}

.c-group__img figure:first-child {
  max-width: 28.53%;
}

@media screen and (max-width: 576px) {
  .c-group__img figure:first-child {
    max-width: 100%;
  }
}

.c-group__img figure:nth-child(2) {
  max-width: 30.76%;
}

@media screen and (max-width: 576px) {
  .c-group__img figure:nth-child(2) {
    max-width: 100%;
  }
}

.c-group__img figure:nth-child(3) {
  max-width: 33.53%;
}

@media screen and (max-width: 576px) {
  .c-group__img figure:nth-child(3) {
    max-width: 100%;
  }
}

.c-group__img figure img {
  width: 100%;
}

.c-box__cover {
  background: #f2f1f1;
  padding: 40px;
  margin-bottom: 55px;
}

@media screen and (max-width: 992px) {
  .c-box__cover {
    margin-bottom: 35px;
    padding: 25px;
  }
}

@media screen and (max-width: 768px) {
  .c-box__cover {
    padding: 15px;
  }
}

.blue-link {
  font-weight: 600;
  color: #4fa9be !important;
}

@media screen and (max-width: 768px) {
  .overflow-x {
    overflow-x: scroll;
  }
}

.c-decor__waterseal {
  position: relative;
  padding-bottom: 75px;
}

@media screen and (max-width: 768px) {
  .c-decor__waterseal {
    padding-bottom: 0;
  }
}

.c-decor__waterseal--content {
  width: calc(71.54% - 70px);
}

@media screen and (max-width: 768px) {
  .c-decor__waterseal--content {
    width: 100%;
  }
}

.c-decor__waterseal--img {
  width: 28.46%;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .c-decor__waterseal--img {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
    position: unset;
  }
}

.c-decor__waterseal--img img {
  width: 100%;
}

.c-video {
  text-align: center;
  margin-bottom: 65px;
}

.c-video iframe {
  max-width: 100%;
}

.c-pagination ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.c-pagination ul li {
  width: 30px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #4fa9be;
  background: #fff;
  margin: 0 5px;
}

@media screen and (max-width: 768px) {
  .c-pagination ul li {
    margin: 0 3px;
  }
}

.c-pagination ul li.dotted {
  border: none;
  width: auto;
  background: none;
}

.c-pagination ul li.is-active {
  background: #4fa9be;
}

.c-pagination ul li.is-active a {
  color: #fff;
}

.c-pagination ul li:first-child, .c-pagination ul li:last-child {
  width: auto;
  padding: 0 15px;
}

@media screen and (max-width: 768px) {
  .c-pagination ul li:first-child, .c-pagination ul li:last-child {
    padding: 0 5px;
  }
}

.c-pagination ul li a,
.c-pagination ul li span {
  text-transform: uppercase;
  font-size: 1.6rem;
  color: #4fa9be;
}

.c-policy {
  max-height: 285px;
  overflow-y: scroll;
  margin-bottom: 45px;
}

.c-confirm__box {
  max-width: 64.53%;
  margin: 0 auto 50px;
  background: #fff;
  padding: 41px 110px;
  display: block;
}

@media screen and (max-width: 1200px) {
  .c-confirm__box {
    max-width: 80%;
  }
}

@media screen and (max-width: 992px) {
  .c-confirm__box {
    padding: 20px 40px;
  }
}

@media screen and (max-width: 768px) {
  .c-confirm__box {
    max-width: 100%;
    padding: 15px;
  }
}

.c-confirm__box input {
  position: absolute;
  z-index: -999999999;
}

.c-confirm__box input:checked + span::after {
  content: '';
  width: 19px;
  height: 9px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  position: absolute;
  left: 5px;
  top: 30%;
  transform: translateY(-50%) rotate(-45deg);
}

@media screen and (max-width: 992px) {
  .c-confirm__box input:checked + span::after {
    width: 10px;
    height: 6px;
    border-width: 2px;
  }
}

.c-confirm__box input:checked + span::before {
  background: #4fa9be;
}

.c-confirm__box span span {
  position: relative;
  padding-left: 58px;
  display: block;
  line-height: 44px;
  font-size: 1.8rem;
}

@media screen and (max-width: 1200px) {
  .c-confirm__box span span {
    font-size: 1.6rem;
    line-height: 32px;
  }
}

@media screen and (max-width: 992px) {
  .c-confirm__box span span {
    padding-left: 35px;
  }
}

.c-confirm__box span span::before {
  content: '';
  width: 30px;
  height: 30px;
  border: 1px solid #707070;
  position: absolute;
  left: 0;
  transform: translateY(-50%);
  top: 33%;
}

@media screen and (max-width: 992px) {
  .c-confirm__box span span::before {
    width: 20px;
    height: 20px;
  }
}

.close_btn {
  background: #a8d7e2;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 0;
  width: 50px;
  height: 50px;
  z-index: 99999;
  cursor: pointer;
}

/* *****************************
 * Slider
 **************************** */
.c-table__cover table {
  width: 100%;
}

.c-table__cover table thead tr {
  background: #cceeee;
}

.c-table__cover table thead tr th,
.c-table__cover table thead tr td {
  border: 1px solid #b7e7e8;
  text-align: center;
}

.c-table__cover table tbody th,
.c-table__cover table tbody td,
.c-table__cover table thead th,
.c-table__cover table thead td {
  line-height: 34px;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: center;
  border: 1px solid #b7e7e8;
  color: #595757;
}

.c-table__cover table tbody th,
.c-table__cover table thead th {
  width: calc(100% / 3);
}

.c-table__cost {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  padding: 15px 30px;
}

.c-table__cost.mb70 {
  margin-bottom: 70px;
}

@media screen and (max-width: 992px) {
  .c-table__cost.mb70 {
    margin-bottom: 45px;
  }
}

.c-table__cost table {
  text-align: left;
  width: 100%;
}

.c-table__cost table tr {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.c-table__cost table tr th,
.c-table__cost table tr td {
  padding: 0 15px;
  line-height: 64px;
  width: 50%;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 576px) {
  .c-table__cost table tr th,
  .c-table__cost table tr td {
    width: 100%;
    display: block;
  }
}

.c-table__cost table tr th {
  background: #1183c3;
  color: #fff;
  position: relative;
}

@media screen and (max-width: 1200px) {
  .c-table__cost table tr th {
    padding: 0 9px;
    font-size: 1.4rem;
    letter-spacing: 0;
  }
}

.c-table__cost table tr th::before {
  content: '';
  width: 12px;
  height: 24px;
  background: url(../images/common/svg/angle-right-blue.svg) no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: calc(100% - 1px);
  transform: translateY(-50%);
}

@media screen and (max-width: 576px) {
  .c-table__cost table tr th::before {
    transform: translateX(-50%) rotate(90deg);
    top: calc(90% - 1px);
    left: 50%;
  }
}

.c-table__cost table tr td {
  background: #eaeaeb;
  font-weight: 600;
  font-size: 1.8rem;
  padding: 0 40px;
}

@media screen and (max-width: 1200px) {
  .c-table__cost table tr td {
    padding: 0 20px;
    font-size: 1.6rem;
  }
}

.c-table__cost h3,
.c-table__cost h4 {
  text-align: center;
  line-height: 77px;
  position: relative;
  z-index: 1;
  font-size: 2.4rem;
  letter-spacing: 0.05em;
  margin-bottom: 15px;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .c-table__cost h3,
  .c-table__cost h4 {
    font-size: 2rem;
    line-height: 66px;
  }
}

@media screen and (max-width: 992px) {
  .c-table__cost h3,
  .c-table__cost h4 {
    font-size: 1.8rem;
    line-height: 55px;
  }
}

.c-table__cost h3::before, .c-table__cost h3::after,
.c-table__cost h4::before,
.c-table__cost h4::after {
  content: '';
  border-top: 2px solid;
  border-bottom: 2px solid;
  width: 100%;
  height: 3px;
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
}

.c-table__cost h3::after,
.c-table__cost h4::after {
  top: unset;
  bottom: 0;
}

.c-table__cost--left, .c-table__cost--right {
  width: 50%;
}

@media screen and (max-width: 992px) {
  .c-table__cost--left, .c-table__cost--right {
    width: 100%;
  }
}

.c-table__cost--left {
  padding-right: 25px;
}

@media screen and (max-width: 992px) {
  .c-table__cost--left {
    padding-right: 15px;
  }
}

@media screen and (max-width: 768px) {
  .c-table__cost--left {
    padding-right: 0;
  }
}

.c-table__cost--right {
  padding-left: 25px;
}

@media screen and (max-width: 992px) {
  .c-table__cost--right {
    padding-left: 15px;
  }
}

@media screen and (max-width: 768px) {
  .c-table__cost--right {
    padding-left: 0;
  }
}

.c-table__cost--note {
  margin-top: 15px;
  display: block;
}

.c-table__compare {
  margin-bottom: 135px;
}

@media screen and (max-width: 768px) {
  .c-table__compare {
    width: 767px;
  }
}

@media screen and (max-width: 992px) {
  .c-table__compare {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 768px) {
  .c-table__compare {
    margin-bottom: 35px;
  }
}

.c-table__compare .gray {
  color: #595757;
}

.c-table__compare .tbcol_bluegreen {
  background: rgba(0, 169, 172, 0.1);
}

.c-table__compare .row02 {
  width: 28%;
}

.c-table__compare tr th,
.c-table__compare tr td {
  border: 1px solid #ccc;
  vertical-align: middle;
  font-weight: 400;
  color: #595757;
}

.c-table__compare tr td {
  text-align: left;
  padding: 8px 15px;
  background-color: #fff;
}

.c-table__compare tr td ol,
.c-table__compare tr td ul {
  margin-bottom: 5px;
  margin-left: 15px;
}

.c-table__compare tr td ol li,
.c-table__compare tr td ul li {
  font-size: 1.5rem;
  line-height: 1.3em;
  text-align: left;
  list-style-type: disc;
  color: #595757;
}

.c-table__compare tr td ul {
  margin: 0px 0px 20px 20px;
}

.c-table__highlight {
  width: 100%;
  margin-bottom: 65px;
}

.c-table__highlight thead tr th,
.c-table__highlight thead tr td,
.c-table__highlight tbody tr th,
.c-table__highlight tbody tr td {
  border: 1px solid #cccccc;
  padding: 9px 20px;
}

@media screen and (max-width: 768px) {
  .c-table__highlight thead tr th,
  .c-table__highlight thead tr td,
  .c-table__highlight tbody tr th,
  .c-table__highlight tbody tr td {
    line-height: 26px;
    padding: 5px;
  }
}

.c-table__highlight thead tr th,
.c-table__highlight tbody tr th {
  background: #e5f6f7;
  text-align: center;
}

.c-table__highlight thead tr th:first-child,
.c-table__highlight tbody tr th:first-child {
  width: 23%;
}

.c-table__highlight thead tr td,
.c-table__highlight tbody tr td {
  font-weight: 500;
}

/* *****************************
 * Text
 **************************** */
.c-txt__2rem {
  font-size: 2rem;
  line-height: 40px;
  color: #707070;
  padding: 0 76px;
  margin-bottom: 90px;
}

@media screen and (max-width: 992px) {
  .c-txt__2rem {
    font-size: 1.8rem;
    line-height: 36px;
    padding: 0 35px;
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 768px) {
  .c-txt__2rem {
    font-size: 1.6rem;
    line-height: 26px;
    padding: 0 15px;
    margin-bottom: 35px;
    padding: 0;
  }
}

.c-txt__2rem.mb0 {
  margin-bottom: 0;
}

.c-txt__2rem.tac {
  text-align: center;
}

.c-txt__2rem.non-padding {
  padding: 0;
}

.c-txt__2rem span {
  color: #4fa9be;
}

.c-txt__2rem span.fsz16rem {
  font-size: 1.6rem;
  color: #b40404;
}

.c-txt__3rem {
  font-size: 3rem;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  color: #393327;
  line-height: 44px;
  letter-spacing: 0.05em;
  padding: 0 140px;
  margin-bottom: 80px;
}

@media screen and (max-width: 992px) {
  .c-txt__3rem {
    font-size: 2.4rem;
    line-height: 32px;
    margin-bottom: 60px;
    padding: 0 70px;
  }
}

@media screen and (max-width: 768px) {
  .c-txt__3rem {
    font-size: 1.8rem;
    line-height: 28px;
    margin-bottom: 40px;
    padding: 0 30px;
  }
}

.c-txt__red {
  font-size: 2.4rem;
  font-weight: 600;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  line-height: 32px;
  color: #c51919;
  margin: 0 auto;
}

@media screen and (max-width: 992px) {
  .c-txt__red {
    font-size: 1.8rem;
    line-height: 28px;
  }
}

.c-txt__red.w74p {
  width: 74.3%;
}

@media screen and (max-width: 768px) {
  .c-txt__red.w74p {
    width: 100%;
  }
}

.c-txt__box {
  background: #257d8f;
  color: #fff;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 32px;
  border-radius: 5px;
  max-width: 79.61%;
  padding: 40px 30px;
  margin: 0 auto 50px;
}

@media screen and (max-width: 992px) {
  .c-txt__box {
    max-width: 85%;
    font-size: 1.6rem;
    padding: 20px 15px;
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 768px) {
  .c-txt__box {
    max-width: 100%;
  }
}

.c-txt__box.mb85 {
  margin-bottom: 85px;
}

@media screen and (max-width: 992px) {
  .c-txt__box.mb85 {
    margin-bottom: 45px;
  }
}

.c-txt__base {
  letter-spacing: 0.05em;
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .c-txt__base {
    margin-bottom: 35px;
  }
}

.c-txt__base.mb0 {
  margin-bottom: 0;
}

.c-txt__base.half-width {
  width: 54%;
}

@media screen and (max-width: 992px) {
  .c-txt__base.half-width {
    width: 65%;
  }
}

@media screen and (max-width: 576px) {
  .c-txt__base.half-width {
    width: 100%;
  }
}

.c-txt__blue {
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  color: #4fa9be;
  padding: 12px;
  line-height: 21px;
  background: #fff;
  margin-top: -15px;
  margin-bottom: 20px;
}

@media screen and (max-width: 992px) {
  .c-txt__blue {
    margin-top: -10px;
    margin-bottom: 10px;
  }
}

/* *****************************
 * Title
 **************************** */
.c-title__underline {
  color: #393327;
  font-size: 4rem;
  line-height: 51px;
  font-weight: 600;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  position: relative;
  padding: 0 15px 20px 15px;
  margin-bottom: 80px;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .c-title__underline {
    font-size: 3.2rem;
    line-height: 40px;
    margin-bottom: 60px;
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 768px) {
  .c-title__underline {
    font-size: 2.4rem;
    line-height: 32px;
    margin-bottom: 45px;
    padding: 0 30px 10px;
  }
}

@media screen and (max-width: 480px) {
  .c-title__underline {
    padding: 0 0 10px;
  }
}

.c-title__underline::before {
  position: absolute;
  content: '';
  width: 300px;
  height: 5px;
  background: #4fa9be;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

@media screen and (max-width: 992px) {
  .c-title__underline::before {
    width: 170px;
  }
}

@media screen and (max-width: 768px) {
  .c-title__underline::before {
    height: 3px;
  }
}

.c-title__solid {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .c-title__solid {
    margin-bottom: 20px;
  }
}

.c-title__solid strong {
  font-size: 4rem;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  line-height: 52px;
  letter-spacing: 0.05em;
  color: #393327;
  display: block;
  margin-bottom: 11px;
  font-weight: 400;
}

@media screen and (max-width: 1200px) {
  .c-title__solid strong {
    font-size: 3.2rem;
    line-height: 38px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 992px) {
  .c-title__solid strong {
    font-size: 2.4rem;
    line-height: 32px;
    margin-bottom: 0;
  }
}

.c-title__solid span {
  font-size: 2rem;
  letter-spacing: 0.05em;
  line-height: 26px;
  color: #4fa9be;
  font-family: '游ゴシック', '游ゴシック体', 'Yu Gothic Medium', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-weight: 600;
}

@media screen and (max-width: 1200px) {
  .c-title__solid span {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 992px) {
  .c-title__solid span {
    font-size: 1.6rem;
  }
}

.c-title__flag {
  padding: 0 0 0 40px;
  position: relative;
  text-align: left;
  width: 100%;
  line-height: 31px;
  color: #393327;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 2.4rem;
}

@media screen and (max-width: 992px) {
  .c-title__flag {
    font-size: 2rem;
    padding-left: 25px;
    margin-bottom: 25px;
  }
}

.c-title__flag.dark-red {
  color: #bb0631;
}

.c-title__flag.dark-red::before {
  background: #bb0631;
}

.c-title__flag.dark-red::after {
  background: #7f9195;
}

.c-title__flag.more-dark::before, .c-title__flag.more-dark::after {
  background: #7f9195;
}

.c-title__flag.mb60 {
  margin-bottom: 60px;
}

@media screen and (max-width: 992px) {
  .c-title__flag.mb60 {
    margin-bottom: 40px;
  }
}

.c-title__flag::before {
  content: '';
  width: 30px;
  height: 7px;
  position: absolute;
  background: #4fa9be;
  top: 10px;
  left: 0;
}

@media screen and (max-width: 992px) {
  .c-title__flag::before {
    width: 15px;
  }
}

.c-title__flag::after {
  content: '';
  width: 100%;
  height: 1px;
  background: #4fa9be;
  position: absolute;
  bottom: -10px;
  left: 0;
}

@media screen and (max-width: 992px) {
  .c-title__flag::after {
    bottom: -5px;
  }
}

.c-title__highlight {
  background: #9cd0db;
  border-radius: 5px;
  color: #fff;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  line-height: 66px;
  font-size: 3rem;
  letter-spacing: 0.05em;
  width: 100%;
  padding: 0 5px;
  text-align: left;
  margin-bottom: 50px;
  font-weight: 400;
}

.c-title__highlight.more-dark {
  background: #7f9195;
}

@media screen and (max-width: 992px) {
  .c-title__highlight {
    font-size: 2.4rem;
    line-height: 45px;
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 768px) {
  .c-title__highlight {
    font-size: 1.8rem;
    line-height: 35px;
  }
}

.c-title__maincolor {
  font-size: 3rem;
  line-height: 60px;
  color: #4fa9be;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  text-align: center;
  font-weight: 400;
}

@media screen and (max-width: 992px) {
  .c-title__maincolor {
    font-size: 2.4rem;
    line-height: 45px;
  }
}

@media screen and (max-width: 768px) {
  .c-title__maincolor {
    font-size: 1.8rem;
    line-height: 32px;
  }
}

.c-title__maincolor.mb60 {
  margin-bottom: 60px;
}

.c-title__base {
  font-size: 1.8rem;
  letter-spacing: 0.05em;
  line-height: 26px;
  font-family: 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  margin-bottom: 22.5px;
  font-weight: 600;
}

.c-title__base.fsz2rem {
  font-size: 2rem;
}

.p-access1__inner {
  padding: 70px 0 40px;
  position: relative;
}

.p-access1__inner::before {
  content: '';
  z-index: 2;
  width: 100%;
  height: calc(12vw + 8px);
  background: url(../images/design1-3/wave-white.svg) no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
}

.p-access2 {
  padding: 40px 0 100px;
}

@media screen and (max-width: 992px) {
  .p-access2 {
    padding: 65px 0;
  }
}

.p-aesthe1__inner {
  padding: 70px 0 75px;
  position: relative;
}

.p-aesthe1__inner::before {
  content: '';
  z-index: 2;
  width: 100%;
  height: calc(12vw + 8px);
  background: url(../images/design1-3/wave-white.svg) no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
}

.p-aesthe2 {
  padding: 100px 0;
  background: #ebf7fa;
}

@media screen and (max-width: 992px) {
  .p-aesthe2 {
    padding: 65px 0;
  }
}

.p-aesthe3 {
  padding: 120px 0;
}

@media screen and (max-width: 992px) {
  .p-aesthe3 {
    padding: 65px 0;
  }
}

.p-aesthe4 {
  padding: 120px 0;
  background: #ebf7fa;
}

@media screen and (max-width: 992px) {
  .p-aesthe4 {
    padding: 65px 0;
  }
}

.p-aesthe4 .l-container__1300px section > figure {
  text-align: center;
  padding: 0 15px;
  margin-bottom: 60px;
}

@media screen and (max-width: 992px) {
  .p-aesthe4 .l-container__1300px section > figure {
    padding: 0;
    margin-bottom: 40px;
  }
}

.p-aesthe4 .l-container__1300px section > figure img {
  max-width: 100%;
}

.p-anti1 {
  background: #ebf7fa;
}

.p-anti1__inner {
  padding: 70px 0;
  position: relative;
}

@media screen and (max-width: 992px) {
  .p-anti1__inner {
    padding: 65px 0;
  }
}

.p-anti1__inner::before {
  content: '';
  z-index: 2;
  width: 100%;
  height: calc(12vw + 8px);
  background: url(../images/design1-1/wave2.svg) no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
}

.p-anti2 {
  padding: 120px 0 50px;
}

@media screen and (max-width: 992px) {
  .p-anti2 {
    padding: 50px 0;
  }
}

.p-backup1__inner {
  padding: 70px 0 50px;
  position: relative;
}

.p-backup1__inner::before {
  content: '';
  z-index: 2;
  width: 100%;
  height: calc(12vw + 8px);
  background: url(../images/design1-1/wave2.svg) no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
}

.p-backup2 {
  padding: 120px 0 80px;
}

@media screen and (max-width: 992px) {
  .p-backup2 {
    padding: 65px 0 45px;
  }
}

.p-backup3 {
  padding: 75px 0;
  background: #ebf7fa;
}

@media screen and (max-width: 992px) {
  .p-backup3 {
    padding: 65px 0;
  }
}

.p-contact1 {
  background: #ebf7fa;
}

.p-contact1__inner {
  padding: 70px 0;
  position: relative;
}

@media screen and (max-width: 992px) {
  .p-contact1__inner {
    padding: 65px 0 20px;
  }
}

.p-contact1__inner::before {
  content: '';
  z-index: 2;
  width: 100%;
  height: calc(12vw + 8px);
  background: url(../images/design1-1/wave2.svg) no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
}

.p-contact2 {
  padding: 160px 0;
  background: #ebf7fa;
}

@media screen and (max-width: 992px) {
  .p-contact2 {
    padding: 120px 0;
  }
}

@media screen and (max-width: 992px) {
  .p-contact2 {
    padding: 65px 0;
  }
}

.p-correct1 {
  background: #ebf7fa;
}

.p-correct1__inner {
  padding: 70px 0;
  position: relative;
}

.p-correct1__inner::before {
  content: '';
  z-index: 2;
  width: 100%;
  height: calc(12vw + 8px);
  background: url(../images/design1-1/wave.svg) no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
}

.p-correct2 {
  padding: 100px 0 165px;
}

@media screen and (max-width: 992px) {
  .p-correct2 {
    padding: 65px 0;
  }
}

.p-correct2 figure.mb60 {
  margin-bottom: 60px;
}

.p-correct3 {
  padding: 90px 0 80px;
  background: #ebf7fa;
}

@media screen and (max-width: 992px) {
  .p-correct3 {
    padding: 65px 0;
  }
}

.p-correct3 figure {
  text-align: center;
}

.p-correct3 figure img {
  max-width: 100%;
}

.p-correct4 {
  padding: 100px 0;
}

.p-decay1__inner {
  padding: 70px 0;
  position: relative;
}

.p-decay1__inner::before {
  content: '';
  z-index: 2;
  width: 100%;
  height: calc(12vw + 8px);
  background: url(../images/design1-3/wave-white.svg) no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
}

.p-decay1__inner section .l-container__1300px section > figure {
  text-align: center;
  margin-bottom: 37px;
}

.p-decay1__inner section .l-container__1300px section > figure.mb60 {
  margin-bottom: 60px;
}

.p-decay1__inner section .l-container__1300px section > figure img {
  max-width: 100%;
}

.p-denture1__inner {
  padding: 70px 0 100px;
  position: relative;
}

@media screen and (max-width: 992px) {
  .p-denture1__inner {
    padding: 65px 0;
  }
}

.p-denture1__inner::before {
  content: '';
  z-index: 2;
  width: 100%;
  height: calc(12vw + 8px);
  background: url(../images/design1-3/wave-white.svg) no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
}

.p-feature1 {
  background: #ebf7fa;
}

.p-feature1__inner {
  padding: 70px 0 140px;
  position: relative;
}

@media screen and (max-width: 992px) {
  .p-feature1__inner {
    padding: 65px 0;
  }
}

.p-feature1__inner::before {
  content: '';
  z-index: 2;
  width: 100%;
  height: calc(12vw + 8px);
  background: url(../images/design1-1/wave2.svg) no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
}

.p-feature2 {
  padding: 110px 0;
}

@media screen and (max-width: 992px) {
  .p-feature2 {
    padding: 65px 0;
  }
}

.p-implant1 {
  background: #ebf7fa;
}

.p-implant1__inner {
  padding: 70px 0;
  position: relative;
}

@media screen and (max-width: 992px) {
  .p-implant1__inner {
    padding: 65px 0;
  }
}

.p-implant1__inner::before {
  content: '';
  z-index: 2;
  width: 100%;
  height: calc(12vw + 8px);
  background: url(../images/design1-1/wave2.svg) no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
}

.p-info1 {
  background: #ebf7fa;
}

.p-info1__inner {
  padding: 70px 0;
  position: relative;
}

.p-info1__inner--row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 45px;
}

.p-info1__inner--row.flex-end {
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  .p-info1__inner--row.flex-end {
    justify-content: space-between;
  }
}

.p-info1__inner--row .c-btn__skeleton--short {
  margin-left: 16px;
}

@media screen and (max-width: 768px) {
  .p-info1__inner--row .c-btn__skeleton--short {
    max-width: calc(50% - 7.5px);
    margin-left: 0;
  }
}

.p-info1__inner::before {
  content: '';
  z-index: 2;
  width: 100%;
  height: calc(12vw + 8px);
  background: url(../images/design1-1/wave2.svg) no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
}

.p-pay1, .p-backup1 {
  background: #ebf7fa;
}

.p-pay1__inner {
  padding: 70px 0 90px;
  position: relative;
}

@media screen and (max-width: 992px) {
  .p-pay1__inner {
    padding: 65px 0;
  }
}

.p-pay1__inner--decor, .p-access1__inner--decor, .p-aesthe1__inner--decor, .p-anti1__inner--decor, .p-backup1__inner--decor, .p-contact1__inner--decor, .p-correct1__inner--decor, .p-decay1__inner--decor, .p-denture1__inner--decor, .p-feature1__inner--decor, .p-implant1__inner--decor, .p-info1__inner--decor, .p-pedia1__inner--decor, .p-welcome1__inner--decor, .p-white1__inner--decor {
  position: absolute;
  left: 50%;
  bottom: calc(100% + 36px);
  transform: translateX(-50%);
  z-index: 3;
  width: 19.23%;
  max-width: 250px;
}

@media screen and (max-width: 1600px) {
  .p-pay1__inner--decor, .p-access1__inner--decor, .p-aesthe1__inner--decor, .p-anti1__inner--decor, .p-backup1__inner--decor, .p-contact1__inner--decor, .p-correct1__inner--decor, .p-decay1__inner--decor, .p-denture1__inner--decor, .p-feature1__inner--decor, .p-implant1__inner--decor, .p-info1__inner--decor, .p-pedia1__inner--decor, .p-welcome1__inner--decor, .p-white1__inner--decor {
    width: 15%;
    bottom: calc(100% + 18px);
  }
}

@media screen and (max-width: 768px) {
  .p-pay1__inner--decor, .p-access1__inner--decor, .p-aesthe1__inner--decor, .p-anti1__inner--decor, .p-backup1__inner--decor, .p-contact1__inner--decor, .p-correct1__inner--decor, .p-decay1__inner--decor, .p-denture1__inner--decor, .p-feature1__inner--decor, .p-implant1__inner--decor, .p-info1__inner--decor, .p-pedia1__inner--decor, .p-welcome1__inner--decor, .p-white1__inner--decor {
    width: 120px;
    bottom: 99%;
  }
}

.p-pay1__inner--decor > img, .p-access1__inner--decor > img, .p-aesthe1__inner--decor > img, .p-anti1__inner--decor > img, .p-backup1__inner--decor > img, .p-contact1__inner--decor > img, .p-correct1__inner--decor > img, .p-decay1__inner--decor > img, .p-denture1__inner--decor > img, .p-feature1__inner--decor > img, .p-implant1__inner--decor > img, .p-info1__inner--decor > img, .p-pedia1__inner--decor > img, .p-welcome1__inner--decor > img, .p-white1__inner--decor > img {
  width: 100%;
}

.p-pay1__inner::before {
  content: '';
  z-index: 2;
  width: 100%;
  height: calc(12vw + 8px);
  background: url(../images/design1-1/wave.svg) no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
}

.p-pay2 {
  padding: 40px 0 65px;
}

.p-pay3 {
  padding: 100px 0;
  background: #ebf7fa;
}

@media screen and (max-width: 992px) {
  .p-pay3 {
    padding: 65px 0;
  }
}

.p-pedia1 {
  background: #ebf7fa;
  padding-bottom: 70px;
}

.p-pedia1__inner {
  padding: 70px 0 75px;
  position: relative;
}

.p-pedia1__inner::before {
  content: '';
  z-index: 2;
  width: 100%;
  height: calc(12vw + 8px);
  background: url(../images/design1-1/wave.svg) no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
}

/* *****************************
 * Top
 **************************** */
.p-welcome1 {
  background: #ebf7fa;
}

.p-welcome1__inner {
  padding: 70px 0 10px;
  position: relative;
}

.p-welcome1__inner::before {
  content: '';
  z-index: 2;
  width: 100%;
  height: calc(12vw + 8px);
  background: url(../images/design1-1/wave.svg) no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
}

.p-welcome2 {
  padding: 150px 0 75px;
}

@media screen and (max-width: 992px) {
  .p-welcome2 {
    padding: 65px 0;
  }
}

.p-welcome3 {
  padding: 120px 0;
  background: #ebf7fa;
}

@media screen and (max-width: 992px) {
  .p-welcome3 {
    padding: 65px 0;
  }
}

.p-white1 {
  background: #ebf7fa;
}

.p-white1__inner {
  padding: 70px 0;
  position: relative;
}

@media screen and (max-width: 992px) {
  .p-white1__inner {
    padding: 65px 0;
  }
}

.p-white1__inner::before {
  content: '';
  z-index: 2;
  width: 100%;
  height: calc(12vw + 8px);
  background: url(../images/design1-1/wave2.svg) no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
}

.p-white2 {
  padding: 110px 0 0;
}

@media screen and (max-width: 992px) {
  .p-white2 {
    padding-top: 65px;
  }
}

.p-white3 {
  padding: 160px 0;
  background: #ebf7fa;
}

@media screen and (max-width: 992px) {
  .p-white3 {
    padding: 65px 0;
  }
}

.p-white3 figure {
  text-align: center;
}
