/* *****************************
 * Article
 **************************** */

.c-article {
    &1 {
        @include fs;
        margin-bottom: 40px;

        &__img {
            width: 100%;
            max-width: 300px;

            @include mq(md) {
                max-width: 100%;
            }

            img {
                width: 100%;
            }
        }

        &__content {
            width: calc(100% - 330px);

            @include mq(md) {
                width: 100%;
                margin-bottom: 35px;
            }

            h4 {
                color: #4fa9be;
                font-size: 2rem;
                line-height: 26px;
                margin-bottom: 15px;

                @include mq(md) {
                    font-size: 1.8rem;
                }
            }
        }
    }

    &2 {
        @include fs;
        margin-bottom: 25px;

        &__img {
            width: 100%;
            max-width: 46.15%;

            @include mq(md) {
                max-width: 100%;
            }

            img {
                width: 100%;
            }
        }

        &__content {
            width: 100%;
            max-width: calc(53.85% - 20px);

            @include mq(md) {
                max-width: 100%;
                margin-bottom: 35px;
            }

            h4 {
                font-size: 2rem;
                letter-spacing: 0.05em;
                line-height: 26px;
                margin-bottom: 20px;
                color: #7f9195;

                @include mq(lg) {
                    font-size: 1.8rem;
                    margin-bottom: 10px;
                }
            }

            p {
                letter-spacing: 0.05em;

                @include mq(lg) {
                    line-height: 26px;
                }
            }
        }
    }

    &3 {
        margin-bottom: 140px;

        @include mq(xl) {
            margin-bottom: 100px;
        }

        @include mq(lg) {
            margin-bottom: 60px;
        }

        @include mq(md) {
            margin-bottom: 35px;
        }

        &__content {
            @include fs;
            margin-bottom: 60px;

            @include mq(md) {
                margin-bottom: 35px;
            }

            &--child {
                width: 48%;

                @include mq(md) {
                    width: 100%;
                }

                &:not(:last-child) {
                    @include mq(md) {
                        margin-bottom: 35px;
                    }
                }

                h4 {
                    font-size: 3rem;
                    line-height: 39px;
                    margin-bottom: 35px;
                    @include yumincho-font;
                    font-weight: 400;

                    @include mq(lg) {
                        font-size: 2.4rem;
                        line-height: 32px;
                        margin-bottom: 25px;
                    }

                    @include mq(md) {
                        font-size: 1.8rem;
                        line-height: 26px;
                        margin-bottom: 15px;
                    }
                }
            }
        }

        &__img {
            text-align: center;

            img {
                max-width: 100%;
                box-shadow: 10px 10px 6px rgba($color: #000000, $alpha: 0.16);
            }
        }
    }

    &4 {
        padding: 60px 30px 100px;
        background: #fff;
        margin-bottom: 100px;

        @include mq(lg) {
            margin-bottom: 65px;
            padding: 30px 15px;
        }

        @include mq(md) {
            margin-bottom: 35px;
        }

        &__row {
            @include fs;
            margin-bottom: 45px;

            &--content {
                width: 100%;
                max-width: 43.3%;

                @include mq(md) {
                    max-width: 100%;
                }

                p {
                    font-size: 1.8rem;
                    line-height: 40px;
                    margin-bottom: 40px;

                    @include mq(lg) {
                        font-size: 1.6rem;
                        line-height: 32px;
                        margin-bottom: 25px;
                    }

                    strong {
                        background: #a2cdd1;
                        font-weight: 400;
                    }
                }
            }

            &--img {
                max-width: 53.62%;
                width: 100%;

                @include mq(md) {
                    max-width: 100%;
                    margin-bottom: 35px;
                }
            }
        }

        figure {
            text-align: center;
        }
    }
}
