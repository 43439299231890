.c-table {
    &__cover {
        table {
            width: 100%;

            thead {
                tr {
                    background: #cceeee;

                    th,
                    td {
                        border: 1px solid #b7e7e8;
                        text-align: center;
                    }
                }
            }

            tbody,
            thead {
                th,
                td {
                    line-height: 34px;
                    font-size: 1.6rem;
                    font-weight: 400;
                    text-align: center;
                    border: 1px solid #b7e7e8;
                    color: #595757;
                }

                th {
                    width: calc(100% / 3);
                }
            }
        }
    }

    &__cost {
        @include f;
        background: #fff;
        padding: 15px 30px;

        &.mb70 {
            margin-bottom: 70px;

            @include mq(lg) {
                margin-bottom: 45px;
            }
        }

        table {
            text-align: left;
            width: 100%;

            tr {
                border-top: 1px solid #fff;
                border-bottom: 1px solid #fff;

                th,
                td {
                    padding: 0 15px;
                    line-height: 64px;
                    width: 50%;
                    letter-spacing: 0.05em;

                    @media screen and (max-width: 576px) {
                        width: 100%;
                        display: block;
                    }
                }

                th {
                    background: #1183c3;
                    color: #fff;
                    position: relative;

                    @include mq(xl) {
                        padding: 0 9px;
                        font-size: 1.4rem;
                        letter-spacing: 0;
                    }

                    &::before {
                        content: '';
                        width: 12px;
                        height: 24px;
                        background: url(../images/common/svg/angle-right-blue.svg) no-repeat;
                        background-size: cover;
                        position: absolute;
                        top: 50%;
                        left: calc(100% - 1px);
                        transform: translateY(-50%);

                        @media screen and (max-width: 576px) {
                            transform: translateX(-50%) rotate(90deg);
                            top: calc(90% - 1px);
                            left: 50%;
                        }
                    }
                }

                td {
                    background: #eaeaeb;
                    font-weight: 600;
                    font-size: 1.8rem;
                    padding: 0 40px;

                    @include mq(xl) {
                        padding: 0 20px;
                        font-size: 1.6rem;
                    }
                }
            }
        }

        h3,
        h4 {
            text-align: center;
            line-height: 77px;
            position: relative;
            z-index: 1;
            font-size: 2.4rem;
            letter-spacing: 0.05em;
            margin-bottom: 15px;
            width: 100%;

            @include mq(xl) {
                font-size: 2rem;
                line-height: 66px;
            }

            @include mq(lg) {
                font-size: 1.8rem;
                line-height: 55px;
            }

            &::before,
            &::after {
                content: '';
                border-top: 2px solid;
                border-bottom: 2px solid;
                width: 100%;
                height: 3px;
                position: absolute;
                left: 0;
                top: 0;
                background: transparent;
            }

            &::after {
                top: unset;
                bottom: 0;
            }
        }

        &--left,
        &--right {
            width: 50%;

            @include mq(lg) {
                width: 100%;
            }
        }

        &--left {
            padding-right: 25px;

            @include mq(lg) {
                padding-right: 15px;
            }

            @include mq(md) {
                padding-right: 0;
            }
        }

        &--right {
            padding-left: 25px;

            @include mq(lg) {
                padding-left: 15px;
            }

            @include mq(md) {
                padding-left: 0;
            }
        }

        &--note {
            margin-top: 15px;
            display: block;
        }
    }

    &__compare {
        margin-bottom: 135px;

        @include mq(md) {
            width: 767px;
        }

        @include mq(lg) {
            margin-bottom: 70px;
        }

        @include mq(md) {
            margin-bottom: 35px;
        }

        .gray {
            color: #595757;
        }

        .tbcol_bluegreen {
            background: rgba(0, 169, 172, 0.1);
        }

        .row02 {
            width: 28%;
        }

        tr {
            th,
            td {
                border: 1px solid #ccc;
                vertical-align: middle;
                font-weight: 400;
                color: #595757;
            }

            td {
                text-align: left;
                padding: 8px 15px;
                background-color: #fff;

                ol,
                ul {
                    margin-bottom: 5px;
                    margin-left: 15px;

                    li {
                        font-size: 1.5rem;
                        line-height: 1.3em;
                        text-align: left;
                        list-style-type: disc;
                        color: #595757;
                    }
                }

                ul {
                    margin: 0px 0px 20px 20px;
                }
            }
        }
    }

    &__highlight {
        width: 100%;
        margin-bottom: 65px;

        thead,
        tbody {
            tr {
                th,
                td {
                    border: 1px solid #cccccc;
                    padding: 9px 20px;

                    @include mq(md) {
                        line-height: 26px;
                        padding: 5px;
                    }
                }

                th {
                    background: #e5f6f7;
                    text-align: center;

                    &:first-child {
                        width: 23%;
                    }
                }

                td {
                    font-weight: 500;
                }
            }
        }
    }
}
