/* *****************************
 * Setup
 **************************** */

$breakpoints: (
    'sm': 'screen and (max-width: 480px)',
    'md': 'screen and (max-width: 768px)',
    'lg': 'screen and (max-width: 992px)',
    'xl': 'screen and (max-width: 1200px)',
) !default;

@mixin mq($breakpoint: md) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

@mixin playfair {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
}

@mixin roboto {
    font-family: 'Roboto', sans-serif;
}

// Import font
