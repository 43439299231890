.p-backup {
    &1 {
        @extend .p-pay1;
        // background: #ebf7fa;

        &__inner {
            padding: 70px 0 50px;
            position: relative;

            &--decor {
                @extend .p-pay1__inner--decor;
            }

            &::before {
                content: '';
                z-index: 2;
                width: 100%;
                height: calc(12vw + 8px);
                background: url(../images/design1-1/wave2.svg) no-repeat;
                background-size: cover;
                position: absolute;
                left: 0;

                bottom: calc(100% - 1px);
            }
        }
    }

    &2 {
        padding: 120px 0 80px;

        @include mq(lg) {
            padding: 65px 0 45px;
        }
    }

    &3 {
        padding: 75px 0;
        background: #ebf7fa;

        @include mq(lg) {
            padding: 65px 0;
        }
    }
}
