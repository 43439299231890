/* *****************************
 * List
 **************************** */

.c-list {
    &1 {
        padding: 0 100px;

        @include mq(lg) {
            padding: 0 50px;
        }

        @include mq(md) {
            padding: 0;
        }

        ul {
            @include f;
            margin: 0 -17px;

            @media screen and (max-width: 576px) {
                margin: 0;
            }

            li {
                width: 100%;
                max-width: calc(100% / 2 - 34px);
                margin: 0 17px 25px;
                background: #a8d7e2;
                border: 3px solid #aaaaaa;
                @include fcc;
                height: 200px;
                color: #fff;
                font-weight: 600;
                @include yumincho-font;
                text-align: center;
                font-size: 2.5rem;
                line-height: 40px;

                @include mq(lg) {
                    font-size: 1.8rem;
                    line-height: 32px;
                    padding: 15px;
                }

                @include mq(md) {
                    line-height: 26px;
                }

                @media screen and (max-width: 576px) {
                    max-width: 100%;
                    height: 100px;
                    margin: 0 0 25px;
                }
            }
        }
    }

    &2 {
        margin-bottom: 100px;

        @include mq(lg) {
            margin-bottom: 50px;
        }

        @include mq(md) {
            margin-bottom: 35px;
        }

        ul,
        ol {
            @include f;
            margin: 0 -25px;

            @media screen and (max-width: 576px) {
                margin: 0;
            }

            li {
                width: 100%;
                max-width: calc(100% / 2 - 50px);
                margin: 0 25px 35px;

                @media screen and (max-width: 576px) {
                    max-width: 100%;
                    margin: 0 0 35px;
                }
            }
        }

        &__content {
            // margin-bottom: 38px;

            p {
                letter-spacing: 0.05em;

                > span {
                    font-size: 2.9rem;
                    letter-spacing: 0.05em;
                    line-height: 37px;
                    @include yumincho-font;
                    display: block;
                    margin-bottom: 28px;

                    @include mq(lg) {
                        font-size: 2rem;
                        line-height: 32px;
                        margin-bottom: 15px;
                    }

                    @include mq(md) {
                        font-size: 1.8rem;
                        line-height: 28px;
                    }
                }

                @include mq(lg) {
                    line-height: 26px;
                }
            }

            &--top {
                @include fsc;
                margin-bottom: 5px;

                h3,
                h4 {
                    font-size: 2.9rem;
                    line-height: 37px;
                    letter-spacing: 0.05em;
                    @include yumincho-font;
                    font-weight: 400;

                    @include mq(lg) {
                        font-size: 2rem;
                        line-height: 32px;
                        width: calc(100% - 75px);
                    }

                    @include mq(md) {
                        font-size: 1.8rem;
                        line-height: 28px;
                    }
                }

                span {
                    display: block;
                    @include fcc;
                    width: 96.85px;
                    height: 96.85px;
                    border-radius: 50%;
                    background: #877858;
                    color: #fff;
                    @include yumincho-font;
                    font-size: 2.3rem;
                    letter-spacing: 0.05em;

                    @include mq(lg) {
                        width: 60px;
                        height: 60px;
                        font-size: 1.8rem;
                    }
                }
            }
        }

        &__img {
            margin-top: 38px;
            text-align: center;

            img {
                max-width: 100%;
            }
        }
    }

    &3 {
        margin-bottom: 110px;

        @include mq(lg) {
            margin-bottom: 55px;
        }

        ol {
            li {
                width: 100%;
                @include fsc;

                &:not(:last-child) {
                    margin-bottom: 30px;

                    @include mq(md) {
                        margin-bottom: 45px;
                    }
                }

                &:nth-child(2n + 2) {
                    flex-direction: row-reverse;
                }
            }
        }

        &__content {
            position: relative;
            width: 50%;

            @media screen and (max-width: 1600px) {
                padding-left: 120px;
            }

            @include mq(lg) {
                width: 60%;
                padding-left: 100px;
            }

            @include mq(md) {
                width: 100%;
                margin-bottom: 35px;
            }

            > span {
                position: absolute;
                @include hobo-font;
                font-size: 3.5rem;
                line-height: 35px;
                color: #fff;
                z-index: 1;
                top: 45px;
                right: calc(100% + 55px);

                @media screen and (max-width: 1600px) {
                    right: unset;
                    left: 45px;
                }

                @include mq(lg) {
                    font-size: 2.3rem;
                    top: 40px;
                }

                &::before {
                    z-index: -1;
                    width: 92.3px;
                    height: 108.16px;
                    background: url(../images/common/svg/icon-point.svg) no-repeat;
                    background-size: cover;
                    content: '';
                    position: absolute;
                    top: 5%;
                    left: 25%;
                    transform: translate(-50%, -50%);

                    @include mq(lg) {
                        width: 68px;
                        height: 80px;
                        top: 10%;
                    }
                }
            }

            h3 {
                font-size: 2.4rem;
                line-height: 31px;
                letter-spacing: 0.05em;
                @include yumincho-font;
                margin-bottom: 40px;
                font-weight: 400;

                @include mq(lg) {
                    font-size: 1.8rem;
                    line-height: 28px;
                    margin-bottom: 20px;
                }
            }

            p {
                letter-spacing: 0.05em;
                width: 97%;

                @include mq(lg) {
                    width: 100%;
                    line-height: 26px;
                }
            }
        }

        &__img {
            width: 34.61%;

            @include mq(md) {
                width: 100%;
            }

            img {
                aspect-ratio: 3/2;
                width: 100%;
            }
        }
    }

    &4 {
        ul {
            margin: 0 -15px;
            @include f;
            justify-content: center;

            @include mq(md) {
                margin: 0 -7.5px;
                justify-content: unset;
            }

            li {
                width: 100%;
                max-width: calc(100% / 4 - 30px);
                margin: 0 15px 80px;

                @include mq(lg) {
                    max-width: calc(100% / 3 - 30px);
                }

                @include mq(md) {
                    max-width: calc(100% / 2 - 15px);
                    margin: 0 7.5px 30px;
                }

                @include mq(sm) {
                    margin-bottom: 20px;
                }

                figure {
                    img {
                        width: 100%;
                        margin-bottom: 20px;

                        @include mq(sm) {
                            margin-bottom: 10px;
                        }
                    }

                    figcaption {
                        @include meiryo-font;
                        font-weight: 400;
                        font-size: 1.8rem;
                        line-height: 27px;
                        color: #707070;

                        @include mq(lg) {
                            font-size: 1.6rem;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
    }

    &5 {
        padding: 0 20px;
        margin-bottom: 45px;

        @include mq(lg) {
            padding: 0 15px;
        }

        @include mq(md) {
            padding: 0;
        }

        &.mb25 {
            margin-bottom: 25px;
        }

        ul {
            margin: 0 -50px;
            @include f;

            @include mq(xl) {
                margin: 0 -25px;
            }

            @include mq(md) {
                margin: 0 -7.5px;
            }

            @media screen and (max-width: 576px) {
                margin: 0;
            }

            li {
                width: 100%;
                max-width: calc(100% / 3 - 100px);
                margin: 0 50px;
                border: 3px solid #aaaaaa;
                text-align: center;
                height: 200px;
                background: #a8d7e2;
                color: #fff;
                @include yumincho-font;
                font-size: 2.5rem;
                @include fcc;

                @include mq(xl) {
                    max-width: calc(100% / 3 - 50px);
                    margin: 0 25px;
                }

                @include mq(lg) {
                    border-width: 2px;
                    height: 120px;
                    font-size: 2rem;
                }

                @include mq(md) {
                    max-width: calc(100% / 2 - 15px);
                    margin: 0 7.5px 15px;
                    border-width: 1px;
                    height: 90px;
                    font-size: 1.8rem;
                }

                @media screen and (max-width: 576px) {
                    margin: 0 0 15px;
                    max-width: 100%;
                }
            }
        }
    }

    &6 {
        margin-bottom: 100px;
        width: 100%;
        padding-right: 160px;
        position: relative;

        @include mq(lg) {
            margin-bottom: 50px;
        }

        @include mq(md) {
            padding-right: 0;
        }

        &::before {
            content: '';
            width: 170px;
            height: 170px;
            position: absolute;
            right: 0;
            top: 5%;
            background: url(../images/common/icon-waterseal.png) no-repeat;
            background-size: cover;

            @include mq(md) {
                width: 120px;
                height: 120px;
                bottom: 80%;
                top: unset;
            }
        }

        ul {
            @include f;
            margin: 0 -14px;

            @include mq(md) {
                margin: 0;
            }

            li {
                width: 100%;
                max-width: calc(100% / 3 - 28px);
                margin: 0 14px 20px;
                border-bottom: 1px dashed #4fa9be;

                @include mq(xl) {
                    max-width: calc(100% / 2 - 28px);
                }

                @include mq(md) {
                    max-width: 100%;
                    margin: 0 0 20px;
                }

                a {
                    @include yumincho-font;
                    color: #393327;
                    font-size: 2rem;
                    line-height: 41px;
                    padding-left: 38px;
                    position: relative;
                    display: block;

                    @include mq(lg) {
                        font-size: 1.6rem;
                        line-height: 32px;
                    }

                    &::before {
                        content: '';
                        width: 28px;
                        height: 29px;
                        background: url(../images/common/svg/icon-double-circle.svg) no-repeat;
                        background-size: cover;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                    }
                }
            }
        }
    }

    &__check {
        @include f;

        li {
            width: 50%;
            font-size: 1.8rem;
            line-height: 23px;
            margin-bottom: 20px;
            color: #707070;
            padding-left: 36px;
            position: relative;

            @include mq(xl) {
                width: 100%;
            }

            @include mq(lg) {
                font-size: 1.6rem;
                padding-left: 25px;
            }

            &::before {
                content: '';
                width: 20px;
                height: 20px;
                background: #fff;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                border: 3px solid #4fa9be;

                @include mq(lg) {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    &__triple {
        ul {
            margin: 0 -25px;
            @include f;

            @include mq(lg) {
                margin: 0 -7.5px;
            }

            li {
                width: 100%;
                max-width: calc(100% / 3 - 50px);
                margin: 0 25px 25px;

                @include mq(lg) {
                    margin: 0 7.5px 15px;
                    max-width: calc(100% / 3 - 15px);
                }

                @include mq(md) {
                    max-width: calc(100% / 2 - 15px);
                }

                figure {
                    width: 100%;

                    img {
                        width: 100%;
                    }

                    figcaption {
                        font-size: 2rem;
                        line-height: 26px;
                        color: #707070;
                        padding: 14px 0;
                        text-align: center;

                        @include mq(lg) {
                            padding: 5px 0;
                            font-size: 1.8rem;
                        }

                        @include mq(md) {
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
    }

    &__feature {
        padding: 0 30px;
        margin-bottom: 80px;

        @include mq(lg) {
            margin-bottom: 45px;
        }

        @include mq(md) {
            padding: 0;
        }

        ul {
            @include f;
            margin: 0 -20px;

            @include mq(lg) {
                margin: 0 -7.5px;
            }

            @media screen and (max-width: 576px) {
                margin: 0;
            }

            li {
                background: #fff;
                border-radius: 5px;
                border: 1px solid #707070;
                width: 100%;
                max-width: calc(100% / 3 - 40px);
                margin: 0 20px 30px;

                @include mq(lg) {
                    margin: 0 7.5px 15px;
                    max-width: calc(100% / 2 - 15px);
                }

                @media screen and (max-width: 576px) {
                    max-width: 100%;
                    margin: 0 0 20px;
                }

                h4 {
                    font-size: 2rem;
                    line-height: 26px;
                    letter-spacing: 0.05em;
                    color: #4fa9be;
                    margin-bottom: 20px;

                    @include mq(lg) {
                        font-size: 1.8rem;
                        margin-bottom: 10px;
                    }
                }

                p {
                    letter-spacing: 0.05em;

                    @include mq(md) {
                        line-height: 26px;
                    }
                }
            }
        }

        &--inner {
            padding: 15px 20px 10px;

            @include mq(lg) {
                padding: 15px;
            }
        }
    }

    &__point {
        position: relative;
        z-index: 1;
        margin-bottom: 140px;

        @include mq(xl) {
            margin-bottom: 100px;
        }

        @include mq(lg) {
            margin-bottom: 65px;
        }

        @include mq(md) {
            margin-bottom: 35px;
        }

        &--decor {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 30.75%;

            @media screen and (max-width: 576px) {
                width: calc(100% - 50px);
                margin: 0 auto;
                position: unset;
            }

            img {
                width: 100%;
            }
        }

        ol {
            li {
                padding-left: 140px;
                position: relative;

                &:not(:last-child) {
                    margin-bottom: 100px;

                    @include mq(lg) {
                        margin-bottom: 80px;
                    }

                    @include mq(md) {
                        margin-bottom: 60px;
                    }
                }

                @include mq(lg) {
                    padding-left: 100px;
                }

                @include mq(md) {
                    padding-left: 0;
                }

                h4 {
                    font-size: 2.4rem;
                    line-height: 39px;
                    letter-spacing: 0.05em;
                    @include yumincho-font;
                    margin-bottom: 40px;

                    @include mq(lg) {
                        font-size: 1.8rem;
                        line-height: 32px;
                        margin-bottom: 20px;
                    }
                }

                > span {
                    position: absolute;
                    @include hobo-font;
                    font-size: 3.5rem;
                    line-height: 35px;
                    color: #fff;
                    z-index: 1;
                    top: 45px;
                    right: calc(100% + 55px);

                    @media screen and (max-width: 1600px) {
                        right: unset;
                        left: 45px;
                    }

                    @include mq(lg) {
                        font-size: 2.3rem;
                        top: 40px;
                    }

                    @include mq(md) {
                        position: unset;
                        display: block;
                        margin-bottom: 15px;
                        padding-left: 25px;
                    }

                    &::before {
                        z-index: -1;
                        width: 92.3px;
                        height: 108.16px;
                        background: url(../images/common/svg/icon-point.svg) no-repeat;
                        background-size: cover;
                        content: '';
                        position: absolute;
                        top: 5%;
                        left: 25%;
                        transform: translate(-50%, -50%);

                        @include mq(lg) {
                            width: 68px;
                            height: 80px;
                            top: 10%;
                        }

                        @include mq(md) {
                            top: 5px;
                            left: 30px;
                        }
                    }
                }
            }
        }
    }

    &__note {
        @extend .l-container;
        max-width: 1450px;
        margin-bottom: 170px;

        @include mq(xl) {
            margin-bottom: 120px;
        }

        @include mq(lg) {
            margin-bottom: 80px;
        }

        @include mq(md) {
            margin-bottom: 50px;
        }

        ol {
            @include f;
            margin: 0 -7.5px;

            @media screen and (max-width: 576px) {
                margin: 0;
            }

            li {
                position: relative;
                z-index: 1;
                width: 100%;
                max-width: calc(100% / 3 - 15px);
                margin: 0 7.5px;

                @include mq(xl) {
                    z-index: -1;
                }

                @include mq(lg) {
                    max-width: calc(100% / 2 - 15px);
                    margin-bottom: 35px;
                }

                @media screen and (max-width: 576px) {
                    max-width: 100%;
                    margin: 0 0 35px;
                }

                > span {
                    position: absolute;
                    @include hobo-font;
                    font-size: 3.5rem;
                    line-height: 35px;
                    color: #fff;
                    z-index: -1;
                    top: 3.5%;
                    left: 9%;

                    @media screen and (max-width: 1600px) {
                        left: 8%;
                        top: 3%;
                    }

                    @include mq(xl) {
                        z-index: 1;
                        top: 5%;
                    }

                    @include mq(lg) {
                        font-size: 2.3rem;
                    }

                    @media screen and (max-width: 576px) {
                        top: 7%;
                        left: 11%;
                    }

                    &::before {
                        z-index: -1;
                        width: 92.3px;
                        height: 108.16px;
                        background: url(../images/common/svg/icon-point.svg) no-repeat;
                        background-size: cover;
                        content: '';
                        position: absolute;
                        top: 5%;
                        left: 25%;
                        transform: translate(-50%, -50%);

                        @include mq(lg) {
                            width: 68px;
                            height: 80px;
                            top: 10%;
                        }
                    }
                }

                figure {
                    width: 100%;

                    img {
                        aspect-ratio: 1/1;
                        width: 100%;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-bottom: 50px;
                        border: 1px solid #ded9d9;

                        @include mq(lg) {
                            margin-bottom: 35px;
                        }

                        @include mq(md) {
                            margin-bottom: 20px;
                        }
                    }

                    figcaption {
                        font-size: 3rem;
                        line-height: 39px;
                        text-align: center;
                        font-weight: 600;
                        @include yumincho-font;

                        @include mq(lg) {
                            font-size: 2.4rem;
                            line-height: 32px;
                        }

                        @include mq(md) {
                            font-size: 1.8rem;
                            line-height: 28px;
                        }
                    }
                }
            }
        }
    }

    &__news {
        margin-bottom: 87.5px;

        @include mq(lg) {
            margin-bottom: 45px;
        }

        &--top {
            @include ffc;
            margin-bottom: 20px;

            @include mq(lg) {
                margin-bottom: 10px;
            }
        }

        ul {
            li {
                margin-bottom: 20px;

                a {
                    display: block;

                    article {
                        padding-bottom: 18.5px;
                        border-bottom: 1px solid #929292;

                        @include mq(lg) {
                            padding-bottom: 10px;
                        }

                        time {
                            color: #707070;
                            font-size: 2rem;
                            font-weight: 600;
                            margin-right: 30px;

                            @include mq(lg) {
                                font-size: 1.6rem;
                                margin-right: 15px;
                            }

                            @include mq(md) {
                                font-size: 1.4rem;
                                margin-right: 10px;
                            }
                        }

                        span {
                            font-size: 1.6rem;
                            line-height: 21px;
                            font-weight: 600;
                            color: #fff;
                            padding: 10px 15px;

                            max-width: 95px;
                            width: 100%;
                            text-align: center;
                            display: block;

                            @include mq(lg) {
                                padding: 5px 15px;
                            }

                            @include mq(md) {
                                font-size: 1.4rem;
                            }

                            &.news {
                                background: #4b90cc;
                            }

                            &.blog {
                                background: #4ac9e9;
                            }
                        }

                        h2 {
                            font-size: 1.8rem;
                            color: #393327;
                            text-align: left;
                            padding: 0;
                            width: unset;
                            margin: 0;

                            @include mq(md) {
                                font-size: 1.6rem;
                                line-height: 28px;
                            }
                        }
                    }
                }
            }
        }
    }
}
