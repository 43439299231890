/* *****************************
 * Title
 **************************** */

.c-title {
    &__underline {
        color: #393327;
        font-size: 4rem;
        line-height: 51px;
        font-weight: 600;
        @include yumincho-font;
        position: relative;
        padding: 0 15px 20px 15px;
        margin-bottom: 80px;
        width: 100%;

        @include mq(xl) {
            font-size: 3.2rem;
            line-height: 40px;
            margin-bottom: 60px;
            padding-bottom: 15px;
        }

        @include mq(md) {
            font-size: 2.4rem;
            line-height: 32px;
            margin-bottom: 45px;
            padding: 0 30px 10px;
        }

        @include mq(sm) {
            padding: 0 0 10px;
        }

        &::before {
            position: absolute;
            content: '';
            width: 300px;
            height: 5px;
            background: #4fa9be;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);

            @include mq(lg) {
                width: 170px;
            }

            @include mq(md) {
                height: 3px;
            }
        }
    }

    &__solid {
        margin-bottom: 30px;

        @include mq(md) {
            margin-bottom: 20px;
        }

        strong {
            font-size: 4rem;
            @include yumincho-font;
            line-height: 52px;
            letter-spacing: 0.05em;
            color: #393327;
            display: block;
            margin-bottom: 11px;
            font-weight: 400;

            @include mq(xl) {
                font-size: 3.2rem;
                line-height: 38px;
                margin-bottom: 5px;
            }

            @include mq(lg) {
                font-size: 2.4rem;
                line-height: 32px;
                margin-bottom: 0;
            }
        }

        span {
            font-size: 2rem;
            letter-spacing: 0.05em;
            line-height: 26px;
            color: #4fa9be;
            @include yugothic-font;
            font-weight: 600;

            @include mq(xl) {
                font-size: 1.8rem;
            }

            @include mq(lg) {
                font-size: 1.6rem;
            }
        }
    }

    &__flag {
        padding: 0 0 0 40px;
        position: relative;
        text-align: left;
        width: 100%;
        line-height: 31px;
        color: #393327;
        @include yumincho-font;
        margin-bottom: 40px;
        font-weight: 600;
        font-size: 2.4rem;

        @include mq(lg) {
            font-size: 2rem;
            padding-left: 25px;
            margin-bottom: 25px;
        }

        &.dark-red {
            color: #bb0631;

            &::before {
                background: #bb0631;
            }

            &::after {
                background: #7f9195;
            }
        }

        &.more-dark {
            &::before,
            &::after {
                background: #7f9195;
            }
        }

        &.mb60 {
            margin-bottom: 60px;

            @include mq(lg) {
                margin-bottom: 40px;
            }
        }

        &::before {
            content: '';
            width: 30px;
            height: 7px;
            position: absolute;
            background: #4fa9be;
            top: 10px;
            left: 0;

            @include mq(lg) {
                width: 15px;
            }
        }

        &::after {
            content: '';
            width: 100%;
            height: 1px;
            background: #4fa9be;
            position: absolute;
            bottom: -10px;
            left: 0;

            @include mq(lg) {
                bottom: -5px;
            }
        }
    }

    &__highlight {
        background: #9cd0db;
        border-radius: 5px;
        color: #fff;
        @include yumincho-font;
        line-height: 66px;
        font-size: 3rem;
        letter-spacing: 0.05em;
        width: 100%;
        padding: 0 5px;
        text-align: left;
        margin-bottom: 50px;
        font-weight: 400;

        &.more-dark {
            background: #7f9195;
        }

        @include mq(lg) {
            font-size: 2.4rem;
            line-height: 45px;
            margin-bottom: 35px;
        }

        @include mq(md) {
            font-size: 1.8rem;
            line-height: 35px;
        }
    }

    &__maincolor {
        font-size: 3rem;
        line-height: 60px;
        color: #4fa9be;
        @include yumincho-font;
        text-align: center;
        font-weight: 400;

        @include mq(lg) {
            font-size: 2.4rem;
            line-height: 45px;
        }

        @include mq(md) {
            font-size: 1.8rem;
            line-height: 32px;
        }

        &.mb60 {
            margin-bottom: 60px;
        }
    }

    &__base {
        font-size: 1.8rem;
        letter-spacing: 0.05em;
        line-height: 26px;
        @include yumincho-font;
        margin-bottom: 22.5px;
        font-weight: 600;

        &.fsz2rem {
            font-size: 2rem;
        }
    }
}
